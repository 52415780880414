import { FormText } from '../FormFields/FormText';
import { useAppSelector } from '../../store/hooks';
import { FormSelect } from '../FormFields/FormSelect';
import {
  AccountInterface,
  AccountsAuthInterfase,
  BusinessUnitInterface,
  DeliveryEnum,
  LocationInterface,
  TaxIdentificationTypeInterface,
} from '../../interfaces';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { AccountSearchFavoriteHistoric } from '../Account/AccountSearch';
import { useCallback, useEffect, useState } from 'react';
import { filterGeneral } from '../../utils/filters';
import { GenericoOption } from '../FormFields/FormSearchOptions';
import { FormSearch } from '../FormFields/FormSearch';
import { FormRadioGroup } from '../FormFields/FormRadioGroup';
import { GeneralTypePhoneServices } from '../../services/GeneralServices';
import { GeneralTypeInterface } from '../../interfaces/Dto/GeneralTypeInterface';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import { ModalLoading } from '../Atoms/ModalLoading';
import {
  getAllAccountAuth,
  getAllAgreementAccountAuth,
} from '../../services/AccountAuthServices';
import { obtenerCodigoArea, obtenerNumero } from '../../utils/format';
import { getAccount } from '../../services';

export interface AccountAuthFormValues {
  abbreviationName: TaxIdentificationTypeInterface;
  identificationNumber: string;
  fullName: string;
  codePhoneNumber?: string;
  phoneId?: string;
  phoneTypeId: string;
  phoneNumber: string;
  acceptsSMS: boolean;
  codecellNumber?: string;
  cellPhoneNumber: string;
  emailId?: string;
  email: string;
  locationId?: string;
  city: string;
  cityCode?: string;
  postalCode: string;
  addressId?: string;
  address: string;
  businessUnit?: BusinessUnitInterface;
  accountSelected?: AccountInterface;
  ShipmentType?: DeliveryEnum;
  reference: string;
  reset?: boolean;
  id?: string;
  agreementId?: string;
  agreementAuthID?: string;
}

interface AccountAuthFormField {
  consignee: AccountAuthFormValues;
  shippingLocation: LocationInterface;
}
interface ConsigneeFormPropsV2<T> {
  formik: FormikProps<T & AccountAuthFormField>;
}

export const AccountAuthForm = <T extends any>({
  formik,
}: ConsigneeFormPropsV2<T>) => {
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const clientId = useAppSelector((state) => state.user.client?.id);

  const user = useAppSelector((state) => state.user)!;

  const errors = formik.errors.consignee as
    | FormikErrors<AccountAuthFormValues>
    | undefined;

  const touched = formik.touched.consignee as
    | FormikTouched<AccountAuthFormValues>
    | undefined;

  const [phonePreSearch, setPhonePreSearch] = useState('');
  const [selectDestination, setselectDestination] = useState<boolean>(false);
  const [phonePrefixList, setPhonePrefixList] = useState<string[]>([]);

  const [disabledIdentification, setDisabledIdentification] =
    useState<boolean>(false);
  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');
  const [agreemetList, setAgreemetList] = useState<[]>([]);

  const updatePhoneSearch = useCallback(() => {
    if (!!formik.values.consignee.codePhoneNumber) {
      setPhonePreSearch(formik.values.consignee.codePhoneNumber);
    } else {
      setPhonePreSearch('');
    }
  }, [formik.values.consignee.codePhoneNumber]);

  useEffect(() => {
    const accountID = '' + user?.client?.id;
    if (!accountID) return;
    if (!formik.values.consignee.agreementAuthID) return;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);
      const accountID = '' + user?.client?.id;
      const accountCode = '' + user?.client?.accountCode;

      const accountAuth = await getAllAccountAuth(
        accountID,
        formik.values.consignee.agreementAuthID,
        accountCode
      );

      var listfavoriteDestination =
        accountAuth?.model as AccountsAuthInterfase[];
      if (!accountAuth?.didError) {
        let client = listfavoriteDestination[0];
        setselectDestination(true);
        setDisabledIdentification(true);

        formik.setValues({
          ...formik.values,
          consignee: {
            ...formik.values.consignee,
            id: client.accountID,
            abbreviationName: identificationTypes.find((t) =>
              (t.abbreviationName === client?.taxIDentificationTypeCode) !==
              undefined
                ? client?.taxIDentificationTypeCode?.replace('-', '')
                : client?.taxIDentificationTypeCode?.replace('-', '')
            )!,
            identificationNumber: client.identificationNumber,
            address: client.addressLine1 ?? '',
            fullName: client.accountFullName ?? '',
            phoneId: client.phoneID?.toString() ?? '',
            phoneNumber: obtenerNumero(client.phoneNumber ?? ''),
            emailId: client.emailID ?? '',
            email: client.email ?? '',
            codePhoneNumber: obtenerCodigoArea(client.phoneNumber) ?? '',
            reset: false,
            accountSelected: {} as AccountInterface,
            agreementId: client.agreementID,
          },
        });

        setLoadingStatus(ConfirmModalStatus.SUCCESS);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + accountAuth?.errorMessage);
        setLoading(true);
      }
    };
    fetchShipments();
  }, [user?.client?.id]);

  const getLocations = async () => {
    const phonetype = await GeneralTypePhoneServices('236');
    if (!!phonetype.didError || !phonetype.model) return;

    const emptyPhonePrefix = { value: 'FIJO', text: 'FIJO' };
    let phonetypeList = phonetype.model as unknown as GeneralTypeInterface[];
    phonetypeList.push(emptyPhonePrefix);

    setPhonePrefixList(
      (phonetypeList as unknown as GeneralTypeInterface[]).map(
        (phone) => phone.value
      )
    );
  };

  const getAccountAgreement = async () => {
    const agreement = await getAllAgreementAccountAuth(
      user?.client?.id,
      undefined,
      undefined
    );
    if (!!agreement.didError || !agreement.model) return;

    let agreementList = [] as any;

    agreement.model?.forEach((items) => {
      agreementList.push({
        value: items.agreementId,
        name: items.paymentModeName + '-' + items.serviceDescription,
      });
    });
    setAgreemetList(agreementList);
  };

  useEffect(() => {
    getLocations();
    getAccountAgreement();
  }, [clientId]);

  return (
    <div className="flex h-full flex-col gap-1">
      {formik.values.consignee.agreementAuthID === undefined && (
        <div>
          <AccountSearchFavoriteHistoric
            title=""
            hideFavAndShip={true}
            selected={formik.values.consignee.accountSelected}
            error={
              touched?.accountSelected && errors?.accountSelected
                ? errors?.accountSelected
                : undefined
            }
            placeholder="Buscar cliente autorizado. Ingresa Identificacion, nombre o telefono"
            favoriteLocations={[]}
            historicLocations={[]}
            onSelectClient={async (client) => {
              setselectDestination(true);
              setDisabledIdentification(true);
              formik.setValues({
                ...formik.values,
                consignee: {
                  ...formik.values.consignee,
                  id: client.id,
                  abbreviationName: identificationTypes.find((t) =>
                    (t.abbreviationName === client?.abreviationName) !==
                    undefined
                      ? client?.abreviationName?.replace('-', '')
                      : client?.abbreviationName?.replace('-', '')
                  )!,
                  identificationNumber: client.identificationNumber,
                  address: client.fiscalAddress ?? '',
                  fullName: client.accountFullName ?? '',
                  phoneId:
                    client.listAccountPhone[0]?.phoneID?.toString() ?? '',
                  phoneTypeId: client.listAccountPhone[0]?.phoneTypeId ?? '',
                  phoneNumber:
                    obtenerNumero(client.listAccountPhone[0]?.phoneNumber) ??
                    '',
                  emailId: client.listAccountEmail[0]?.emailID ?? '',
                  email: client.listAccountEmail[0]?.email ?? '',
                  codePhoneNumber:
                    obtenerCodigoArea(
                      client.listAccountPhone[0]?.phoneNumber
                    ) ?? '',
                  accountSelected: client,
                  reset: false,
                },
              });
            }}
            onSelectDestination={async (destination) => {
              const consigneeFind =
                (await getAccount(destination.account.id)) ?? undefined;

              formik.setValues({
                ...formik.values,
                consignee: {
                  ...formik.values.consignee,
                  businessUnit: destination.businessUnit,
                  abbreviationName: identificationTypes.find(
                    (t) =>
                      t.abbreviationName ===
                      destination.account?.abbreviationName
                  )!,
                  identificationNumber:
                    destination.account.identificationNumber,
                  address: destination.location.address ?? '',
                  fullName: destination.account.accountFullName ?? '',
                  codePhoneNumber:
                    obtenerCodigoArea(
                      consigneeFind?.listAccountPhone[0]?.phoneNumber ?? ''
                    ) ?? '',
                  phoneNumber:
                    obtenerNumero(
                      consigneeFind?.listAccountPhone[0]?.phoneNumber ?? ''
                    ) ?? '',
                  email: destination.account.listAccountEmail[0]?.email ?? '',
                  postalCode: destination.location.postalCode ?? '',
                  accountSelected: destination.account,
                  reset: false,
                  emailId: consigneeFind?.listAccountEmail[0]?.emailID ?? '',
                  phoneId:
                    consigneeFind?.listAccountPhone[0]?.phoneID?.toString() ??
                    '',
                  phoneTypeId:
                    consigneeFind?.listAccountPhone[0]?.phoneTypeId?.toString() ??
                    '',
                  ShipmentType: destination.isHomeDelivery
                    ? DeliveryEnum.DELIVERY
                    : DeliveryEnum.OFFICE,
                },
              });
              setselectDestination(true);
              setDisabledIdentification(true);
            }}
            openCreationModal={() => {
              formik.setValues({
                ...formik.values,
                consignee: {
                  ...formik.values.consignee,
                  abbreviationName: identificationTypes.find(
                    (t) => t.abbreviationName === 'V-'
                  )!,
                  identificationNumber: '',
                  codePhoneNumber: '',
                  address: '',
                  fullName: '',
                  phoneNumber: '',
                  email: '',
                  businessUnit: undefined,
                  accountSelected: {} as AccountInterface,
                  reset: false,
                },
              });
              setselectDestination(true);
              setDisabledIdentification(false);
            }}
          />{' '}
          <hr className="my-6" />
        </div>
      )}

      {selectDestination === true ||
      (formik.values.consignee.agreementAuthID !== undefined &&
        formik.values.consignee.agreementAuthID !== '') ? (
        <div className="flex h-full flex-col gap-1 mt-1 pl-6">
          <h4 className="text-sm text-main-500 gap-2 font-bold mb-3 mt-1">
            Detalles del cliente autorizado:
          </h4>
          <FormSelect
            autoComplete="off"
            id="consignee.abbreviationName"
            name="consignee.abbreviationName"
            label="Tipo de Documento"
            selected={formik.values.consignee.abbreviationName}
            options={identificationTypes}
            error={
              touched?.abbreviationName && errors?.abbreviationName
                ? errors?.abbreviationName
                : undefined
            }
            onBlur={formik.handleBlur}
            className="!h-7"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            optionString={(option) => option.abbreviationName.slice(0, 1)}
            onSelectOption={(option) =>
              formik.setFieldValue('consignee.abbreviationName', option)
            }
            disabled={disabledIdentification}
          />

          <FormText
            id="consignee.identificationNumber"
            name="consignee.identificationNumber"
            label="Nro. Documento"
            value={formik.values.consignee.identificationNumber}
            error={
              touched?.identificationNumber && errors?.identificationNumber
                ? errors?.identificationNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              if (
                formik.values.consignee.abbreviationName.abbreviationName !==
                'P-'
              ) {
                // Only numbers
                re = /^[0-9\b]{0,10}$/;
              } else {
                // Only numbers and letters
                re = /^[a-zA-Z0-9\b]{0,10}$/;
              }

              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            disabled={disabledIdentification}
          />

          <FormText
            id="consignee.fullName"
            name="consignee.fullName"
            label="Nombre"
            value={formik.values.consignee.fullName}
            error={
              touched?.fullName && errors?.fullName
                ? errors?.fullName
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />
          <FormSearch
            id="consignee.codePhoneNumber"
            name="consignee.codePhoneNumber"
            label="Operadora"
            value={phonePreSearch}
            options={phonePrefixList}
            unselect={!!formik.values.consignee.codePhoneNumber}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.codePhoneNumber && errors?.codePhoneNumber
                ? errors?.codePhoneNumber
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => setPhonePreSearch(e.target.value)}
            onSelectOption={(option) =>
              formik.setFieldValue('consignee.codePhoneNumber', option)
            }
            onChangeFocus={(focus) => !focus && updatePhoneSearch()}
            style={{ maxWidth: '15rem' }}
            onFilter={filterGeneral}
            RenderOption={({ option }) => <GenericoOption text={option} />}
          />

          <FormText
            id="consignee.phoneNumber"
            name="consignee.phoneNumber"
            label="Teléfono"
            value={formik.values.consignee.phoneNumber}
            error={
              touched?.phoneNumber && errors?.phoneNumber
                ? errors?.phoneNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,10}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            maxLength={
              formik.values.consignee.codePhoneNumber !== 'FIJO' ? 7 : 10
            }
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          <FormText
            id="consignee.email"
            name="consignee.email"
            label="Correo"
            value={formik.values.consignee.email}
            error={touched?.email && errors?.email ? errors?.email : undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          <FormRadioGroup
            id="consignee.agreementId"
            name="consignee.agreementId"
            label="Contratos"
            selected={formik.values.consignee.agreementId}
            error={
              touched?.email && errors?.agreementId
                ? errors?.agreementId
                : undefined
            }
            labelClassname="!text-xs flex sm:!justify-end sm:w-32"
            className="flex flex-1 sm:ml-0 sm:gap-5 sm:items-center flex-col sm:flex-row"
            wrapperClassName="flex !flex-row ml-4"
            optionsClassName="text-xs !ml-1"
            options={agreemetList}
            onSelectOption={(opt) => {
              formik.setFieldValue('consignee.agreementId', opt);
            }}
          />

          <ModalLoading
            open={loading}
            title={loadingTitle}
            status={loadingStatus}
            setOpen={setLoading}
            onPrint={() => {}}
          />
        </div>
      ) : (
        <div /* Este es el div 2 */ className="red2" />
      )}
    </div>
  );
};
