import { FC, useState } from 'react';
import { Modal } from '../Atoms/Modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AccountInterface } from '../../interfaces';
import { PrimaryButton, SecondaryButton } from '../Atoms/Buttons';
import { filterAccountBusinessUnit } from '../../utils/filters';
import { FormSearch } from '../FormFields/FormSearch';
import { AccountSiteOption } from '../FormFields/FormSearchOptions';

interface AccountChangeClientModalProps {
  accounList: AccountInterface[];
  account: AccountInterface;
  open: boolean;
  onSaveChange: (accountSelected: AccountInterface) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AccountChangeClientModal: FC<AccountChangeClientModalProps> = ({
  accounList,
  account,
  open,
  onSaveChange,
  setOpen,
}) => {
  const [accountSiteSelected, setAccountSiteSelected] =
    useState<AccountInterface>();
  const [businessUnitSearch, setBusinessUnitSearch] = useState('');
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className="flex flex-1 mx-4 my-8"
      style={{ maxWidth: '55rem' }}
    >
      <div className="flex flex-1 flex-col">
        {/* Header */}
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-xl text-main-500 font-bold">Cambiar Cliente</h2>

          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
              onClick={() => setOpen(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <hr className="border-gray-300 my-3" />

        {/* Body */}
        <div className="flex flex-1 flex-col text-gray-800 leading-0 text-sm">
          <div className="flex flex-1 flex-col px-2 py-5 bg-gray-100 border rounded shadow-inner">
            <FormSearch
              id="businessUnit"
              name="businessUnit"
              placeholder="Seleccione la cuenta de un cliente"
              value={businessUnitSearch}
              options={accounList}
              unselect={!!accountSiteSelected}
              labelClassname="!text-xs"
              onChange={(e) => setBusinessUnitSearch(e.target.value)}
              onSelectOption={(option) => {
                setBusinessUnitSearch(option?.accountFullName ?? '');
                setAccountSiteSelected(option);
              }}
              onFilter={filterAccountBusinessUnit}
              RenderOption={({ option }) => <AccountSiteOption {...option} />}
            />
          </div>
        </div>

        <hr className="border-gray-300 my-6" />

        {/* Footer */}
        <div className="flex flex-1 justify-between items-center">
          <PrimaryButton onClick={() => setOpen(false)}>
            <span className="w-20">Cerrar</span>
          </PrimaryButton>

          <SecondaryButton
            onClick={() => {
              if (!!accountSiteSelected && accountSiteSelected !== account) {
                onSaveChange(accountSiteSelected);
              }
            }}
          >
            <span className="w-20">Cambiar</span>
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};
