import { v4 } from 'uuid';
import {
  AccountDTO,
  AccountInterface,
  Address,
  BusinessUnitDTO,
  BusinessUnitDTOV2,
  BusinessUnitInterface,
  CCWIntegracionV2,
  CategoryDto,
  ContentDescription,
  DeliveryEnum,
  DestinationDTO,
  DestinationInterface,
  IntegrationInterfase,
  LocationInterface,
  PaymentModeEnum,
  PaymentModeId,
  PieceCategoryInterface,
  PieceInterface,
  PreShipmentInterface,
  PreshipmentDto,
  PreshipmentV2Dto,
  ShipmentDetail,
  ShipmentInterface,
  ShipmentResponseSearchDto,
  ShipmentStatusEnum,
  ShippingMethodEnum,
  TrackingEnum,
  UpdStarStandUserInterfase,
  UseAccountDto,
  UserAccountsInterfase,
} from '../interfaces';
import { ShipmentDtoV2 } from '../interfaces/Shipment/ShipmentDtoV2';
import { WeightTypeEnum } from '../interfaces/Shipment/WeightTypeEnum';
import { ImportInterface } from '../interfaces/Import/importInterfase';
import { formantPaymentModeId } from './format';
import moment from 'moment';
import { ShipmentService } from '../interfaces/Services/ServicesEnum';

export const businessUnitMapper = (
  dto: BusinessUnitDTO
): BusinessUnitInterface => {
  return {
    buid: dto.buid,
    buName: dto.buName,
    buCode: dto.buCode,
    polygonList: dto.polygonList,
    location: {
      name: dto.buAreaName ?? '',
      code: dto.buCode,
      address: dto.address ?? '',
      locationCode: dto.locationCode ?? '',
      reference: dto.reference,
      postalCode: dto.postalCode ?? '',
      coordinates: {
        lat: !!dto.latitude ? +dto.latitude : 0,
        lng: !!dto.longitude ? +dto.longitude : 0,
      },
    },
  };
};

export const accountMapper = (dto: AccountDTO): AccountInterface => {
  return {
    id: dto.id ?? dto.AccountID,
    accountCode: dto.accountCode,
    taxIdentificationTypeID: dto.taxIdentificationTypeID,
    taxIDentificationTypeCode: dto.taxIDentificationTypeCode,
    agreementId: dto.agreementId,
    abbreviationName: dto.abreviationName,
    cityName: dto.cityName,
    cityCode: dto.cityCode,
    identificationNumber: dto.identificationNumber,
    accountFullName: dto.accountFullName,
    fiscalAddress: dto.fiscalAddress,
    listAccountPhone: dto.listAccountPhone,
    listAccountEmail: dto.listAccountEmail,
    listAuthorizingAccount: dto.listAuthorizingAccount,
    creationUser: dto.creationUser,
    creationDate: dto.creationDate,
    accountSiteId: dto.accountSiteId,
    accountBillToId: dto.accountBillToId,
    contactPerson: dto.contactPerson,
    serviceID: dto.serviceID,
  };
};

export const destinationMapper = (
  dto: DestinationDTO
): DestinationInterface => {
  return {
    account: {
      id: dto.accountID,
      accountCode: dto.accountCode,
      abbreviationName: dto.abbreviationName,
      identificationNumber: dto.identificationNumber,
      accountFullName: dto.accountFullName,
      fiscalAddress: '',
      listAccountPhone: [
        {
          id: dto.accountPhoneID,
          accountId: dto.accountID,
          countryId: dto.accountPhoneCountryID,
          countryPhoneAccessCode: dto.accountCountryPhoneAccessCode,
          phoneTypeId: dto.accountPhoneTypeID,
          phoneNumber: dto.accountPhoneNumber,
        },
      ],
      listAccountEmail: [],
      listAuthorizingAccount: [],
    },
    location: {
      id: dto.locationID,
      name: dto.addressName,
      address:
        dto.addressLine1 + (!!dto.addressLine2 ? ' ' + dto.addressLine2 : ''),
      reference: dto.addressReference,
      postalCode: dto.postalCode,
      coordinates: {
        lat: 0,
        lng: 0,
      },
    },
    businessUnit: businessUnitMapper(dto.businessUnit),
    isHomeDelivery: dto.isHomeDelivery,
  };
};

export const PreshipmentMapper = (
  dto: PreshipmentDto
): PreShipmentInterface => {
  return {
    id: dto.preShipmentHeaderID,
    shipmentNumber: dto.preShipmentNumber,
    service: dto.serviceCode,
    totalPieces: dto.totalPieces,
    totalWeight: dto.totalPhysicalWeight,
    deliveryType: dto.deliveryType as DeliveryEnum,
    shippingMethod: dto.shippingMethod as ShippingMethodEnum,
    sender: {
      fullName: '',
    },
    shipper: {
      id: v4(),
      accountCode: dto.accountCode,
      identificationNumber: dto.shipperIdentification,
      accountFullName: dto.shipperName,
      fiscalAddress: dto.shipperAddress,
      abbreviationName: dto.shipperName,
      listAccountPhone: [],
      listAccountEmail: [],
      listAuthorizingAccount: [],
      cityCode: dto.shipperCity ?? '',
    },
    consignee: {
      id: v4(),
      accountCode: dto.accountCode,

      identificationNumber: dto.consigneeIdentification,
      accountFullName: dto.consigneeName,
      fiscalAddress: dto.consigneeAddress,
      abbreviationName: dto.consigneeIdentificationType,
      listAccountPhone: [],
      listAccountEmail: [],
      listAuthorizingAccount: [],
      cityCode: dto.consigneeCity ?? '',
    },
    isSED: dto.isSED,
    isFragile: dto.isFragile,
    isPreAlerted: dto.isPreAlerted,
    isRepacke: dto.isRepackage,
    isSafeKeeping: dto.isSafeKeeping,
    isToHold: dto.isToHold,

    status: dto.statusName as ShipmentStatusEnum,
    shippingLocation: {
      name: dto.shipperLocation ?? '',
      code: dto.shipperLocationCode ?? '',
      address: dto.shipperAddress,
      postalCode: '',
      coordinates: {
        lat: 0,
        lng: 0,
      },
    },
    consigneeLocation: {
      name: dto.consigneeLocation ?? '',
      code: dto.consigneeLocationCode ?? '',
      address: dto.consigneeAddress,
      postalCode: '',
      coordinates: {
        lat: 0,
        lng: 0,
      },
    },
    businessUnitOrigin: {
      buid: 0,
      buName: dto.buCodeSourceName,
      buCode: dto.buCodeSource,
      location: {
        name: '',
        address: '',
        postalCode: '',
        coordinates: {
          lat: 0,
          lng: 0,
        },
      },
    },
    businessUnitConsignee: {
      buid: 0,
      buName: dto.buCodeConsigneeName,
      buCode: dto.buCodeConsignee,
      location: {
        name: '',
        address: '',
        postalCode: '',
        coordinates: {
          lat: 0,
          lng: 0,
        },
      },
    },
    number: 0,
  };
};

export const CategoryMapper = (dto: CategoryDto): PieceCategoryInterface => {
  return {
    id: dto.categoryID.toString(),
    name: dto.categoryName,
    code: dto.categoryCode,
  };
};

function limitStringLength(value: string, maxLength: number): string {
  return value.length > maxLength ? value.substring(0, maxLength) : value;
}

export const PreshipmentMapperDto = (
  dto: PreShipmentInterface
): PreshipmentV2Dto => {
  return {
    ServiceCode: dto.paymentModeID?.toString() === '3' ? '20' : '40',
    CustomerCode: dto.customerCode,
    //totalWeight: dto.totalWeight ?? 0,
    PaymentModeID: dto.paymentModeID?.toString() ?? '3',
    DeliveryType: (dto.deliveryType as DeliveryEnum) ?? 2,
    ShippingMethod: (dto.shippingMethod as ShippingMethodEnum) ?? 0,
    PackageType: dto.packageType,
    MeasureUnitType: 10,
    ShipperCity: dto.shipper.cityCode,
    ConsigneeCity: dto.consignee.cityCode,
    BUCodeSource: dto.businessUnitOrigin?.buCode ?? 'NN', //FALTAN al logearse
    BUCodeConsignee: dto.businessUnitConsignee.buCode,
    DeclaratedValue: dto.declaredValue,
    ShipperIdentificationType: dto.sender.abbreviationName,
    ShipperIdentification: dto.sender.identificationNumber ?? '',
    ShipperName: limitStringLength(dto.sender.fullName, 50),
    ConsigneeIdentificationType: dto.consignee.abbreviationName ?? '',
    ConsigneeIdentification: dto.consignee.identificationNumber,
    ConsigneeName: limitStringLength(dto.consignee.accountFullName, 50),
    ShipperAddress:
      dto.shipper.fiscalAddress ?? dto.shipper.businessUnit?.location.address,
    ShipperAddressLine2: '',
    ShipperPostalCode: '',
    ConsigneeAddressType: '10',
    ConsigneeAddress: dto.shippingLocation.address,
    ConsigneeAddressLine2: '',
    ConsigneePostalCode: dto.shippingLocation.postalCode,
    ShipperPhone: dto.shipper.listAccountPhone[0]?.phoneNumber,
    ShipperCellPhone: dto.shipper.listAccountPhone[1]?.phoneNumber,
    ShipperAcceptsSMS: dto.shipper.listAccountPhone[0]?.acceptsSMS,
    ConsigneePhone: dto.consignee.listAccountPhone[0]?.phoneNumber,
    ConsigneeCellPhone: dto.consignee.listAccountPhone[1]?.phoneNumber,
    ShipperEmailType: 2,
    ShipperEmail: dto.shipper.listAccountEmail[0]?.email,
    ConsigneeEmail: dto.shipper.listAccountEmail[0]?.email,
    ConsigneeContactPerson: dto.sender.fullName,
    isFragile: dto.isFragile,
    IsSafeKeeping: dto.isSafeKeeping,
    IsRepackage: false,
    IsDelivery: false,
    ShipperAddressType: '10',
    ConsigneeAcceptsSMS: dto.consignee.listAccountPhone[0]?.acceptsSMS,
    ConsigneeEmailType: 2,
    UserLogin: 'ccw',
    Observations: dto.observations ?? '',
    ShipperLatitude: dto.shipper.businessUnit!.location.coordinates!.lat,
    ShipperLongitude: dto.shipper.businessUnit!.location.coordinates!.lng,
    ConsigneeLatitude: dto.shippingLocation.coordinates.lat,
    ConsigneeLongitude: dto.shippingLocation.coordinates.lng,
    ContentDescription: dto.pieces?.map((dto: PieceInterface, index) =>
      PieceInterfaceMapper(dto, index)
    ),
    ShipmentDetail: dto.pieces?.map((dto: PieceInterface, index) =>
      PieceInterfaceDetailsMapper(dto, index)
    ),
  };
};

export const PieceInterfaceMapper = (
  dto: PieceInterface,
  index: number
): ContentDescription => {
  return {
    PieceNumber: index + 1,
    Height: dto.height ?? 0,
    Width: dto.width,
    Lenght: dto.length,
    PhysicalWeight: dto.weight,
    ProductCode: dto.category.code,
  };
};

export const PieceInterfaceDetailsMapper = (
  dto: PieceInterface,
  index: number
): ShipmentDetail => {
  return {
    PieceNumber: index + 1,
    Height: dto.height ?? 0,
    Width: dto.width,
    Lenght: dto.length,
    PhysicalWeight: dto.weight,
    DeclaratedValue: isNaN(dto.declaredValue) ? 0 : dto.declaredValue,
  };
};

export const ShipmentMapperDto = (dto: ShipmentInterface): ShipmentDtoV2 => {
  return {
    guideRequered: '1',
    ServiceID: parseInt(dto.service) ?? 60,
    CustomerCode: dto.customerCode,
    PaymentModeID: parseInt(dto.paymentModeID ?? '3'),
    ShipperTaxIdentTypeID: dto.sender.abbreviationId ?? 1,
    ConsigneeTaxIdentTypeID: dto.consignee.taxIdentificationTypeID ?? 1,
    ShipperCountry: 'VE',
    ConsigneeCountry: 'VE',
    CurrencyID: 1,
    DeclaratedValueCurrency: 'VES',
    AccountTypeID: 3,
    StatusID: 5,
    ShipperAccountID: dto.shipper.id,
    accountSiteId:
      dto.shipper.accountSiteId === 0 ? undefined : dto.shipper.accountSiteId,
    ConsigneeAddressLandMark: dto.consignee.reference,
    ShipperLatitude: dto.shipper.businessUnit!.location.coordinates!.lat,
    ShipperLongitude: dto.shipper.businessUnit!.location.coordinates!.lng,
    ConsigneeLatitude: dto.shippingLocation.coordinates.lat,
    ConsigneeLongitude: dto.shippingLocation.coordinates.lng,
    ShipmentHeaderReference: dto.trackingDetails,
    ConsigneeCountryID: dto.consignee.listAccountPhone[0]?.countryId ?? '253',
    WeightUnitID: (dto.weightUnit as WeightTypeEnum) ?? 10,
    //totalWeight: dto.totalWeight ?? 0,
    DeliveryTypeID: (dto.deliveryType as DeliveryEnum) ?? 2,
    ShippingMethodID: (dto.shippingMethod as ShippingMethodEnum) ?? 0,
    PackageTypeID: dto.packageType,
    MeasureUnitTypeID: 10,
    ShipperCity: dto.shipper.cityCode,
    ConsigneeCity: dto.consignee.cityCode,
    BUCodeSource: dto.businessUnitOrigin?.buCode ?? 'NN', //FALTAN al logearse
    BUCodeConsignee: dto.businessUnitConsignee.buCode,
    DeclaratedValue: dto.declaredValue,
    ShipperTaxIdentTypeCode: dto.sender.abbreviationName,
    ShipperIdentificationNumber:
      dto.sender.identificationNumber?.toString() ?? '',
    ShipperName: dto.sender.fullName,
    ConsigneeTaxIdentTypeCode: dto.consignee.abbreviationName ?? '',
    ConsigneeIdentificationNumber:
      dto.consignee.identificationNumber?.toString(),
    ConsigneeName: dto.consignee.accountFullName,
    ShipperAddress:
      (dto.shipper.fiscalAddress ?? dto.shipper.businessUnit!.location.address)
        .length > 0
        ? dto.shipper.fiscalAddress ??
          dto.shipper.businessUnit!.location.address
        : 'N/A Tienda Virtual',
    ShipperAddressLine2: '',
    ShipperPostalCode: '',
    ConsigneeAddressName: dto.shippingLocation.name ?? '',
    ConsigneeAddress: dto.shippingLocation.address,
    ConsigneeAddressLine2: '',
    ConsigneePostalCode: dto.shippingLocation.postalCode,
    ShipperPhone: dto.shipper.listAccountPhone[0]?.phoneNumber ?? '0000',
    ShipperCellPhone: dto.shipper.listAccountPhone[1]?.phoneNumber ?? '0000',
    ConsigneePhone: dto.consignee.listAccountPhone[0]?.phoneNumber,
    ConsigneeCellPhone: dto.consignee.listAccountPhone[1]?.phoneNumber,
    ShipperEmail:
      dto.shipper.listAccountEmail[0]?.email.length > 0
        ? dto.shipper.listAccountEmail[0]?.email
        : 'ccwGeneral@tealca.com',
    ConsigneeEmail:
      dto.consignee.listAccountEmail[0]?.email !== undefined &&
      dto.consignee.listAccountEmail[0]?.email.length > 0
        ? dto.consignee.listAccountEmail[0]?.email
        : 'ccwGeneral@tealca.com',
    ConsigneeContactPerson: dto.sender.fullName,
    isFragile: dto.isFragile,
    IsSafeKeeping: dto.isSafeKeeping,
    IsRepackage: false,
    AcceptsSMS: dto.consignee.listAccountPhone[0]?.acceptsSMS,
    CreationUser: dto.creationUser,
    Observations: dto.observations ?? '',
    ApplicationId: 2,
    ContentDescription: dto.pieces?.map((dto: PieceInterface, index) =>
      PieceInterfaceMapper(dto, index)
    ),
    ShipmentDetail: dto.pieces?.map((dto: PieceInterface, index) =>
      PieceInterfaceDetailsMapper(dto, index)
    ),
    ExternalManfiest: dto.externalManfiest,
    AccountBillToID: dto.accountBillToID,
  };
};

export const LocationMapperDto = (dto: Address): LocationInterface => {
  return {
    id: dto.addressID,
    name: dto.addressName,
    code: dto.addressShortCode,
    address: dto.addressLine1,
    postalCode: dto.postalCode,
    locationCode: dto.locationID.toString(),
    coordinates: {
      lat: dto.latitude,
      lng: dto.longitude,
    },
  };
};

export const ShipmentSearchMapperDto = (
  dto: ShipmentResponseSearchDto
): ShipmentInterface => {
  var consigne = accountMapper(dto.consignee as AccountDTO);
  consigne.fiscalAddress = dto.consigneeAddress.addressName;
  consigne.countryName = dto.consigneeAddress.countryName;

  return {
    id: dto.shipmentHeaderID,
    number: dto.shipmentNumber,
    service: dto.serviceID,
    paymentMode: formantPaymentModeId(
      dto.paymentModeID.toString() as PaymentModeId
    ) as PaymentModeEnum,
    deliveryTypeID: dto.deliveryTypeID,
    observations: dto.observations,
    totalPieces: dto.totalPieces,
    totalAmount: dto.totalAmount,
    totalWeight: dto.totalPhysicalWeight,
    totalDimensionalWeight: dto.totalDimensionalWeight,
    totalVolumeCf3: dto.totalDimensionalCF,
    consignee: consigne,
    businessUnitOrigin: dto.buSource,
    businessUnitConsignee: dto.buConsignee,
    consigneeLocation: dto.buConsignee.location,
    statusName: dto.statusName,
    statusID: dto.statusID,
    creationDate: dto.creationDate,
    creationUser: dto.creationUser,
    tracking: TrackingEnum.TRACKING,
    trackingDetails: dto.shipmentHeaderReference,
    deliveryType: dto.deliveryTypeID as DeliveryEnum,
    shippingMethod: dto.shippingMethodID as ShippingMethodEnum,
    sender: {
      fullName: '',
      abbreviationName: '',
      identificationNumber: '',
      valueDeclarate: '',
    },
    shipper: accountMapper(dto.shipper),
    shippingLocation: dto.buSource.location,
    isHighValueCargo: false,
    isFragile: false,
    isSED: false,
    isToHold: false,
    isRepacke: false,
    isPreAlerted: false,
    isSafeKeeping: dto.isSafeKeeping,
    status: dto.statusName as ShipmentStatusEnum,
    externalManfiest: dto.externalManfiest,
    shipmentDetails: dto.shipmentDetails,
  };
};

export const ImportMapperDto = (dto: IntegrationInterfase): ImportInterface => {
  return {
    A: dto.date?.toString().trim() ?? '',
    B: dto.warehouse?.toString().trim() ?? '',
    C: '10',
    D: dto.consigneeCity?.toString().trim() ?? '',
    E: '',
    F: 'V',
    G: dto.shipperID?.toString().trim() ?? '',
    H: dto.shipper?.toString().trim() ?? '',
    I: 'V',
    J: dto.consigneeID?.toString().trim() ?? '',
    K: dto.consignee?.toString().trim() ?? '',
    L: dto.consigneePhone?.toString().trim() ?? '',
    M: dto.consigneeEmail?.toString().trim() ?? '',
    N: dto.consigneeAddress?.toString().trim() ?? '',
    O: '',
    P: 'Integracion CCW',
    Q: dto.pieces?.toString().trim() ?? '1',
    R: dto.chargeableWeight?.toString().trim() ?? '0',
    S: dto.height?.toString().trim() ?? '1',
    T: dto.width?.toString().trim() ?? '1',
    U: dto.length?.toString().trim() ?? '1',
    V: dto.paymentTerms?.toString().trim() ?? '0',
    W: '1',
    id: v4(),
  };
};

export const ImportMapperV2Dto = (dto: CCWIntegracionV2): ImportInterface => {
  return {
    A: dto.date?.toString().trim() ?? '',
    B: dto.shipmentNumber?.toString().trim() ?? '',
    C: dto.deliveryType?.toString().trim() ?? '',
    D: dto.consigneeCity?.toString().trim() ?? '',
    E: dto.consigneeBu?.toString().trim() ?? '',
    F: 'J',
    G: '',
    H: dto.shipperName?.toString().trim() ?? '',
    I: dto.consigneeDocumentType?.toString().trim() ?? '',
    J: dto.consigneeDocument?.toString().trim() ?? '',
    K: dto.consigneeName?.toString().trim() ?? '',
    L: dto.consigneePhone?.toString().trim() ?? '',
    M: dto.consigneeEmail?.toString().trim() ?? '',
    N: dto.consigneeAddress?.toString().trim() ?? '',
    O: dto.consigneePostal?.toString().trim() ?? '',
    P: dto.observation?.toString().trim() ?? '',
    Q: dto.pieces?.toString().trim() ?? '1',
    R: dto.grossWeight?.toString().trim() ?? '0',
    S: dto.height?.toString().trim() ?? '1',
    T: dto.width?.toString().trim() ?? '1',
    U: dto.length?.toString().trim() ?? '1',
    V: dto.declaratedValue?.toString().trim() ?? '0',
    W: dto.categoryType?.toString().trim() ?? '0',
    id: v4(),
  };
};

export const mapBUDtoToBUInterface = (
  businessUnit: BusinessUnitDTOV2
): BusinessUnitInterface => {
  return {
    buid: businessUnit.buid,
    buName: businessUnit.buName,
    buCode: businessUnit.buCode,
    location: {
      id: businessUnit?.locationID?.toString(),
      name: businessUnit.buAreaName ?? '',
      address: businessUnit.address ?? '',
      reference: businessUnit.addressLandMark ?? '',
      postalCode: businessUnit.postalCode ?? '',
      locationCode: businessUnit.locationCode,
      coordinates: {
        lat: !!businessUnit.latitude ? parseFloat(businessUnit.latitude) : 0,
        lng: !!businessUnit.longitude ? parseFloat(businessUnit.longitude) : 0,
      },
    },
    polygonList: businessUnit.polygonList ?? [],
    buTypeID: businessUnit.buTypeID,
    isLocked: businessUnit.isLocked,
  };
};

export const UserAccountMapperDto = (
  dto: UserAccountsInterfase
): UpdStarStandUserInterfase => {
  return {
    accountList: [
      {
        accountID: dto.accountID,
        accountSiteID: dto.accountSiteID,
        userAccountID: dto.id,
      },
    ] as UseAccountDto[],
    userID: dto.userID,
    departmentID: dto.departamentID,
    taxIdentificationTypeID: dto.userIdentificationTypeID ?? 0,
    userIdentificationNumber: dto.userIdentificationNumber,
    userName: dto.userName,
    userLastName: dto.userLastName,
    userEmail: dto.userEmail,
    userPhone: dto.userPhone,
    userLogin: dto.userLogin,
    userPassword:
      dto.userID === undefined
        ? 'Tealca' + moment().format('YYYY')
        : 'Tealca' + moment().format('YYYY'),
    passwordExpirationDate: moment().add(10, 'days').format('YYYY-MM-DD'),
    businessUnits: undefined,
    profilesList: [dto.profileID ?? 0],
    applicationCode: '020',
    StatusID: dto.statusID,
    AllAccountClient: dto.allAccountClient,
  };
};

export const mapBusinessUnitDtoListToBusinessUnitInterface = (
  businessUnitDto: BusinessUnitDTO[]
): BusinessUnitInterface[] => {
  return businessUnitDto.map((businessUnit: BusinessUnitDTO) =>
    mapBUDtoToBUInterface(businessUnit)
  );
};

export const mapServiceToEnum = (
  service: string
): ShipmentService | undefined => {
  switch (service) {
    case '60':
      return ShipmentService.STANDARD;
    case '61':
      return ShipmentService.DOCUMENT;
    case '62':
      return ShipmentService.INTERNATIONAL;
    case '64':
      return ShipmentService.ECONOMIC;
    case '81':
      return ShipmentService.NATIONAL_LOCKER;
  }
};
