import axios from 'axios';
import {
  ListResponse,
  SimpleResponse,
} from '../interfaces/Shipment/ListResponseDto';
import {
  businessUnitMapper,
  mapBUDtoToBUInterface,
  mapBusinessUnitDtoListToBusinessUnitInterface,
} from '../utils/mappers';
import {
  Nullable,
  BusinessUnitDTO,
  BusinessUnitInterface,
  ServiceDTO,
  PaymentModeId,
  DeliveryEnum,
} from '../interfaces';
import { AuthenticationServices } from './AuthenticationServices';
import { BusinessUnitDTOV2 } from '../interfaces/BusinessUnit/BusinessUnitInterface';

const { REACT_APP_URL_PATH_API } = process.env;

export const getBusinessUnits = async (): Promise<
  Nullable<BusinessUnitInterface[]>
> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/BusinessUnitV2?status=1&IsNational=false&excludeOperationLocked=true`;
  try {
    const response = await axios.get(uri);
    if (response.status === 200) {
      const storeDto = response.data;
      var datamap = storeDto?.map((dto: BusinessUnitDTO) =>
        businessUnitMapper(dto)
      );
      return datamap;
    }
  } catch (error) {}
  return null;
};

export const getBusinessUnitsType = async (
  type: number
): Promise<Nullable<BusinessUnitInterface[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/BusinessUnitV2?status=1&IsNational=false&excludeOperationLocked=true`;
  try {
    const response = await axios.get(uri);
    if (response.status === 200) {
      const storeDto = response.data;
      var stoteDtoBu = storeDto as BusinessUnitDTOV2[];
      stoteDtoBu = stoteDtoBu.filter(
        (x) => x.buTypeID?.toString() === type.toString()
      );
      var datamap = stoteDtoBu?.map((dto: BusinessUnitDTO) =>
        businessUnitMapper(dto)
      );
      return datamap;
    }
  } catch (error) {}
  return null;
};

export const getBusinessUnitsPolly = async (): Promise<
  Nullable<BusinessUnitInterface[]>
> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/BusinessUnitV2?status=1&IsNational=false&withPolygonsList=true&excludeOperationLocked=true`;
  try {
    const response = await axios.get(uri);
    if (response.status === 200) {
      const storeDto = response.data;
      var datamap = storeDto?.map((dto: BusinessUnitDTO) =>
        businessUnitMapper(dto)
      );
      return datamap.filter((bu: BusinessUnitInterface) => bu.buCode != '8110');
    }
  } catch (error) {}

  return null;
};

export const getBusinessUnitByTerritory = async (
  longitude: number,
  latitude: number
): Promise<SimpleResponse<BusinessUnitInterface>> => {
  const token = await AuthenticationServices();
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/GetBusinessUnitByTerritory?longitude=${longitude}&latitude=${latitude}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      const storeDto = response.data.model;
      if (storeDto != null) {
        response.data.model = mapBUDtoToBUInterface(storeDto);
      }
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: 'No se pudo obtener la tienda de cobertura del punto especificado',
    errorMessage:
      'No se pudo obtener la tienda de cobertura del punto especificado',
  };
};

export const getBusinessUnitsInnovus = async (): Promise<
  Nullable<BusinessUnitInterface[]>
> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/BusinessUnitV2?status=1&withPolygonsList=false&IsNational=false&includePlatforms=true`;
  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      const storeDto = response.data;
      return mapBusinessUnitDtoListToBusinessUnitInterface(storeDto);
    }
  } catch (error) {}

  return null;
};

export const getServicesFromPaymentMode = async (
  paymentModeID: PaymentModeId
): Promise<ListResponse<ServiceDTO[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/getServicesFromPaymentMode?PaymentModeID=${paymentModeID}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: 'No se pudieron obtener los servicios',
    errorMessage: 'No se pudieron obtener los servicios',
  };
};

export const getServicesFromDeliveryType = async (
  deliveryTypeID: DeliveryEnum
): Promise<ListResponse<ServiceDTO[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/GetServicesFromDeliveryType?DeliveryTypeID=${deliveryTypeID}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: 'No se pudieron obtener los servicios',
    errorMessage: 'No se pudieron obtener los servicios',
  };
};

export const getLockedServicesByTier = async (
  tierID: number
): Promise<ListResponse<ServiceDTO[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/GetLockedServicesByTier?TierID=${tierID}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: 'No se pudieron obtener los servicios bloqueados',
    errorMessage: 'No se pudieron obtener los servicios bloqueados',
  };
};

export const getLockBUServices = async (
  buCode: string
): Promise<ListResponse<ServiceDTO[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/GetLockBUServices?BUCode=${buCode}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: 'No se pudieron obtener los servicios bloqueados',
    errorMessage: 'No se pudieron obtener los servicios bloqueados',
  };
};

export const getAppLockedServices = async (
  applicationID: number
): Promise<ListResponse<ServiceDTO[]>> => {
  const uri = `${REACT_APP_URL_PATH_API}/BusinessUnit/GetAppLockedServices?ApplicationID=${applicationID}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: 'No se pudieron obtener los servicios bloqueados',
    errorMessage: 'No se pudieron obtener los servicios bloqueados',
  };
};
