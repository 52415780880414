import { FC, useEffect } from 'react';
import './App.css';
import AppRoutes from './utils/AppRoutes';
import { LogoutTimer } from './components';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getBusinessUnitsInnovus } from './services/BusinessUnitServices';
import {
  alertService,
  getCities,
  getCitiesByBu,
  getTaxIdentificationTypes,
} from './services';
import {
  setInmutableCities,
  setInmutableBusinessUnits,
  setInmutablePieceCategories,
  setInmutableTaxIdentificationTypes,
  setInmutableCitiesByBu,
  setInmutableRangeDeclareList,
  setInmutableExchangeRate,
  setVolumenMin,
  setDensityMax,
  setHighDeclaredValue,
  setInmutableAppData,
} from './store/slices/inmutable';
import { getBaseAppData } from './services/applicationDataService';
import { CategoryMapper } from './utils/mappers';
import { AppDataInterface } from './interfaces/AppDataInterface';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const logged = useAppSelector((state) => state.user.logged);
  useEffect(() => {
    const findTaxIdentificationTypes = async () => {
      const taxIdTypes = await getTaxIdentificationTypes();
      if (!!taxIdTypes) {
        dispatch(setInmutableTaxIdentificationTypes(taxIdTypes));
      }
    };

    if (logged) findTaxIdentificationTypes();
  }, [logged]);

  // useEffect(() => {
  //   const findPiecesCategories = async () => {
  //     const categories = await getPieceCategories();
  //     if (!!categories) {
  //       dispatch(setInmutablePieceCategories(categories));
  //     }
  //   };

  //   if (logged) findPiecesCategories();
  // }, [logged]);

  // Fetch stores
  useEffect(() => {
    const getAllBusinessUnits = async () => {
      const businessUnits = await getBusinessUnitsInnovus();
      if (!!businessUnits) {
        dispatch(setInmutableBusinessUnits(businessUnits));
      }
    };

    getAllBusinessUnits();
  }, [logged]);

  useEffect(() => {
    const findCities = async () => {
      const cities = await getCities();
      if (!!cities) dispatch(setInmutableCities(cities));
    };

    if (logged) findCities();
  }, [logged]);

  useEffect(() => {
    const findCitiesBybu = async () => {
      const cities = await getCitiesByBu();
      if (!!cities) dispatch(setInmutableCitiesByBu(cities));
    };

    if (logged) findCitiesBybu();
  }, [logged]);

  useEffect(() => {
    const getAppData = async () => {
      const response = await getBaseAppData(null);
      if (!response.didError && !!response.model) {
        const appDataDto = response.model;

        dispatch(
          setInmutableAppData({
            applicationName: appDataDto.applicationName,
            applicationID: appDataDto.applicationID,
            applicationDescription: appDataDto.applicationDescription,
            applicationCode: appDataDto.applicationCode,
          } as AppDataInterface)
        );

        // Set Range Declare List
        if (!!appDataDto.rangeDeclareList) {
          dispatch(setInmutableRangeDeclareList(appDataDto.rangeDeclareList));
        }
        // Set Exchange Rate
        if (!!appDataDto.exchangeRate) {
          dispatch(setInmutableExchangeRate(appDataDto.exchangeRate));
        }
        // Set RangeValue
        if (!!appDataDto.volumenMin) {
          dispatch(setVolumenMin(appDataDto.volumenMin));
        }
        if (!!appDataDto.densityMax) {
          dispatch(setDensityMax(appDataDto.densityMax));
        }
        if (!!appDataDto.highDeclaredValue) {
          dispatch(setHighDeclaredValue(appDataDto.highDeclaredValue));
        }
        // Set Piece Categories
        if (!!appDataDto.shippingCategoryList) {
          const categoriesMapper =
            appDataDto.shippingCategoryList.map(CategoryMapper);
          dispatch(setInmutablePieceCategories(categoriesMapper));
        }
      } else {
        alertService.error(
          'Error al cargar los datos de la aplicación',
          response.errorMessage,
          { autoClose: false }
        );
      }
    };

    getAppData();
  }, [logged]);

  return (
    <>
      <Routes>
        {AppRoutes.filter((r) => r.auth == !!logged).map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
      <LogoutTimer />
    </>
  );
};
export default App;
