import { FC, Fragment, useRef, useState, useEffect } from 'react';
import { Popover as PopoverHeadless, Transition } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

interface PopoverProps {
  button: React.ReactNode;
  containerClassname?: string;
  children: React.ReactNode;
}

export const Popover: FC<PopoverProps> = ({
  button,
  containerClassname,
  children,
}) => {
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const updatePopoverPosition = () => {
      if (buttonRef.current) {
        const { top, left, width, height } =
          buttonRef.current.getBoundingClientRect();
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        let popoverLeft = left + window.scrollX + width;
        let popoverTop = top + window.scrollY;

        if (popoverLeft + 300 > screenWidth) {
          // Assuming popover width is 300px
          popoverLeft = left + window.scrollX - 300;
        }

        if (popoverLeft < 0) {
          popoverLeft = left + window.scrollX;
          popoverTop = top + window.scrollY + height;
        }

        if (popoverTop + 200 > screenHeight) {
          // Assuming popover height is 200px
          popoverTop = top + window.scrollY - 200;
        }

        setPopoverPosition({ top: popoverTop, left: popoverLeft });
      }
    };

    updatePopoverPosition();
    window.addEventListener('resize', updatePopoverPosition);
    window.addEventListener('scroll', updatePopoverPosition);

    return () => {
      window.removeEventListener('resize', updatePopoverPosition);
      window.removeEventListener('scroll', updatePopoverPosition);
    };
  }, [buttonRef.current]);

  return (
    <PopoverHeadless.Group className="flex gap-x-12">
      <PopoverHeadless>
        <PopoverHeadless.Button
          ref={buttonRef}
          className="flex items-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-400 rounded-md"
        >
          {button}
        </PopoverHeadless.Button>

        {ReactDOM.createPortal(
          <div
            style={{
              position: 'absolute',
              top: popoverPosition.top,
              left: popoverPosition.left,
            }}
          >
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverHeadless.Panel
                className={classNames(
                  'absolute z-10 mt-2 max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5',
                  containerClassname
                )}
              >
                <div>{children}</div>
              </PopoverHeadless.Panel>
            </Transition>
          </div>,
          document.body
        )}
      </PopoverHeadless>
    </PopoverHeadless.Group>
  );
};
