export interface LocationInterface {
  id?: string;
  name: string;
  code?: string;
  address: string;
  reference?: string;
  postalCode: string;
  locationCode?: string;
  coordinates: CoordinatesInterface;
}

export interface CoordinatesInterface {
  lat: number;
  lng: number;
}

export enum BusinessType {
  EXTERNAL = 10,
  INTERNAL = 20,
  JUNIOR = 30,
  PLATAFORM = 40,
  TRANSPORTIST = 50,
  PORT = 60,
  CARRIER = 70,
  INT_OFFICE = 80,
  USA_AGENT = 90,
  ESP_AGENT = 100,
  PANAMA = 110,
  COMMERCIAL_ALLY = 120,
  RETURN_WAREHOUSE = 130,
  COMMERCIAL_VIRTUAL_STORE = 140,
  ADMINISTRATIVE_UNIT = 150,
  VIRTUAL_STORE_FOR_INTERNAL_USE = 160,
}
