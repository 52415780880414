import { useState, useEffect, FC, useMemo } from 'react';
import { Modal } from '../Atoms/Modal';
import LoadingIcon from '../LoadingIcon';
import { FormSearch } from './FormSearch';
import { useAppSelector } from '../../store/hooks';
import { LinkText, PrimaryButton } from '../Atoms/Buttons';
import { getBusinessUnitByTerritory } from '../../services';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { BusinessUnitInterface, LocationInterface } from '../../interfaces';

const INITIAL_COORDS = {
  lat: 10.48801,
  lng: -66.87919,
};

export enum AutocompleteRegion {
  /**
   * Allows any location
   */
  NONE,
  /**
   * Only allows locations within Venezuela
   */
  NATIONAL,
  /**
   * Only allows locations within TEALCA regions
   */
  TEALCA,
  /**
   * Only allows locations outside of Venezuela
   */
  INTERNATIONAL,
}

interface FormMapsAutocompleteProps {
  id: string;
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  region?: AutocompleteRegion;
  selected?: LocationInterface;
  onSelectLocation: (
    location: LocationInterface,
    businessUnit?: BusinessUnitInterface
  ) => void;

  error?: string;
  placeholder?: string;
}
export const FormMapsAutocomplete: FC<FormMapsAutocompleteProps> = ({
  id,
  name,
  label = '',
  required = false,
  disabled = false,
  className,
  region = AutocompleteRegion.NONE,
  selected,
  onSelectLocation,
  error,
  placeholder = 'Buscar ubicación',
}) => {
  const user = useAppSelector((state) => state.user);
  const [search, setSearch] = useState('');
  const [country, setCountry] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [mapsSearch, setMapsSearch] = useState('');
  const [mapsLoading, setMapsLoading] = useState(false);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [mapsTimerId, setMapsTimerId] = useState<NodeJS.Timeout | null>(null);

  const [marker, setMarker] = useState(INITIAL_COORDS);
  const [mapsCenter, setMapsCenter] = useState(INITIAL_COORDS);
  const [countryCoords, setCountryCoords] = useState(INITIAL_COORDS);

  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [mapsOptions, setMapsOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const autocompleteRestrictions = useMemo(() => {
    if (
      region === AutocompleteRegion.NATIONAL ||
      region === AutocompleteRegion.TEALCA
    ) {
      return {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 've' },
      };
    } else {
      return {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country },
      };
    }
  }, [region, country]);

  const mapsRestrictions = useMemo(() => {
    const venezuelaBounds = new window.google.maps.LatLngBounds({
      north: 12.2,
      east: -59.8,
      south: 0.6,
      west: -73.3,
    });

    if (
      region === AutocompleteRegion.NATIONAL ||
      region === AutocompleteRegion.TEALCA
    ) {
      return {
        minZoom: 7,
        restriction: { latLngBounds: venezuelaBounds, strictBounds: false },
      };
    }

    return { minZoom: 4 };
  }, [region]);

  const openError = (msg: string) => {
    setErrorMsg(msg);
    setMapsLoading(false);
    setOpenMapModal(false);
    setOpenErrorModal(true);
  };

  const placeCallback = async (
    details: google.maps.places.PlaceResult | null,
    status: google.maps.places.PlacesServiceStatus
  ) => {
    // Check if the place was found
    const OK = window.google.maps.places.PlacesServiceStatus.OK;
    const ZERO = window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS;
    if (status !== OK || !details) {
      if (status === ZERO) {
        openError('No se encontraron resultados');
      } else {
        openError('Hubo un error al cargar los resultados');
      }
      return;
    }

    const lat = details.geometry?.location?.lat() ?? 0;
    const lng = details.geometry?.location?.lng() ?? 0;

    // Get country and TEALCA region
    const country = details.address_components?.find((component) =>
      component.types.includes('country')
    )?.long_name;
    const territoryResponse = await getBusinessUnitByTerritory(lng, lat);
    const placeTerritory = territoryResponse.model;

    // VALIDATIONS

    // Check if the location is within Venezuela
    if (country !== 'Venezuela' && region === AutocompleteRegion.NATIONAL) {
      openError(
        'La ubicación seleccionada no se encuentra dentro de Venezuela'
      );
      return;
    }
    // Check if the location is within TEALCA regions
    if (
      !placeTerritory &&
      territoryResponse.didError &&
      region === AutocompleteRegion.TEALCA
    ) {
      openError(territoryResponse.errorMessage);
      return;
    }
    // Check if the location is outside of Venezuela
    if (
      country === 'Venezuela' &&
      region === AutocompleteRegion.INTERNATIONAL
    ) {
      openError('La ubicación seleccionada debe ser fuera de Venezuela');
      return;
    }

    // Get location data
    const location: LocationInterface = {
      name: details.name ?? '',
      address: details.formatted_address ?? '',
      coordinates: { lat, lng },
      postalCode:
        details.address_components?.find((component) =>
          component.types.includes('postal_code')
        )?.long_name ?? '',
    };

    onSelectLocation(location, placeTerritory ?? undefined);
    setMapsLoading(false);
    setOpenMapModal(false);
  };

  const mapsPlaceCallback = async (
    details: google.maps.places.PlaceResult | null,
    status: google.maps.places.PlacesServiceStatus
  ) => {
    // Check if the place was found
    const OK = window.google.maps.places.PlacesServiceStatus.OK;
    const ZERO = window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS;
    if (status !== OK || !details) {
      if (status === ZERO) {
        openError('No se encontraron resultados');
      } else {
        openError('Hubo un error al cargar los resultados');
      }
      return;
    }

    const lat = details.geometry?.location?.lat() ?? 0;
    const lng = details.geometry?.location?.lng() ?? 0;

    setMarker({ lat, lng });
    setMapsCenter({ lat, lng });
    setMapsSearch(details.name ?? '');
  };

  const handlePlaceSelect = (
    place?: google.maps.places.AutocompletePrediction
  ) => {
    if (!place) return;

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails({ placeId: place.place_id }, placeCallback);
  };

  const handleMapsPlaceSelect = (
    place?: google.maps.places.AutocompletePrediction
  ) => {
    if (!place) return;

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails({ placeId: place.place_id }, mapsPlaceCallback);
  };

  const handleCoordsSelect = (lat: number, lng: number) => {
    const geocoder = new window.google.maps.Geocoder();
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );

    setMapsLoading(true);
    const request1 = {
      location: new window.google.maps.LatLng(lat, lng),
    };

    geocoder.geocode(request1, (results, status) => {
      if (
        status !== window.google.maps.GeocoderStatus.OK ||
        !results ||
        results.length === 0
      ) {
        if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          openError('No se encontraron resultados');
        } else {
          openError('Hubo un error al cargar los resultados');
        }
        return;
      }

      const filtered = results.filter(
        (r) => r.types.includes('route') || r.types.includes('intersection')
      );
      const selected = filtered[0] ?? results[0];
      const request2: google.maps.places.PlaceDetailsRequest = {
        placeId: selected.place_id ?? '',
      };

      placesService.getDetails(request2, placeCallback);
    });
  };

  useEffect(() => {
    if (window.google) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      if (timerId) {
        clearTimeout(timerId);
      }
      if (search.length > 0) {
        const newTimerId = setTimeout(() => {
          autocompleteService.getPlacePredictions(
            { input: search, ...autocompleteRestrictions },
            (options) => {
              if (options) {
                setOptions(options);
              } else {
                setOptions([]);
              }
            }
          );
        }, 1000);

        setTimerId(newTimerId);
      } else {
        setOptions([]);
      }
    }
  }, [search]);

  useEffect(() => {
    if (window.google) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      if (mapsTimerId) {
        clearTimeout(mapsTimerId);
      }
      if (mapsSearch.length > 0) {
        const newTimerId = setTimeout(() => {
          autocompleteService.getPlacePredictions(
            { input: mapsSearch, ...autocompleteRestrictions },
            (options) => {
              if (options) {
                setMapsOptions(options);
              } else {
                setMapsOptions([]);
              }
            }
          );
        }, 1000);

        setMapsTimerId(newTimerId);
      } else {
        setMapsOptions([]);
      }
    }
  }, [mapsSearch]);

  useEffect(() => {
    // If there are a selected location, set the marker and map center
    if (
      !!selected &&
      selected.coordinates.lat !== 0 &&
      selected.coordinates.lng !== 0
    ) {
      setMarker(selected.coordinates);
      setMapsCenter(selected.coordinates);
    }
    // If there are a country and the region is INTERNATIONAL, set the marker
    // and map center with the country coordinates
    else if (region === AutocompleteRegion.INTERNATIONAL && country) {
      setMarker(countryCoords);
      setMapsCenter(countryCoords);
    }
    // Set the marker and map center with the initial coordinates
    else {
      setMarker(INITIAL_COORDS);
      setMapsCenter(INITIAL_COORDS);
    }
  }, [selected, region, country, countryCoords]);

  useEffect(() => {
    setMapsSearch(search);
  }, [search]);

  return (
    <div className="relative w-full">
      <FormSearch
        hideGlass
        id={id}
        name={name}
        label={label}
        error={error}
        value={search}
        disabled={disabled}
        options={options}
        className={className}
        placeholder={placeholder}
        isRequired={required}
        onChange={(e) => setSearch(e.target.value)}
        onSelectOption={handlePlaceSelect}
        onChangeFocus={(focus) => {
          if (!focus && selected) {
            setSearch(selected.name ?? '');
          }
        }}
        RenderOption={({ option }) => <p> {option.description} </p>}
      />

      <LinkText
        text="Google Maps"
        className="absolute right-4 bottom-1.5 h-5 text-sm hidden sm:block"
        disabled={disabled}
        onClick={() => setOpenMapModal(true)}
      />
      <LinkText
        text="Maps"
        className="absolute right-4 bottom-3 h-5 text-sm block sm:hidden"
        disabled={disabled}
        onClick={() => setOpenMapModal(true)}
      />

      <Modal
        open={openMapModal}
        setOpen={setOpenMapModal}
        className="w-full mx-4 md:mx-16 lg:mx-24 xl:mx-40 2xl:mx-80"
      >
        <div className="flex flex-1 flex-col mb-4">
          {/* Header */}
          <div className="flex flex-row items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">
              Buscar en Google Maps
            </h2>
            <LinkText
              text="Cancelar"
              className="text-sm"
              onClick={() => setOpenMapModal(false)}
            />
          </div>

          {/* Search */}
          <div className="relative mt-4">
            <FormSearch
              id={`google-maps-${id}`}
              name={`google-maps-${name}`}
              value={mapsSearch}
              options={mapsOptions}
              placeholder="Buscar dirección..."
              onChange={(e) => setMapsSearch(e.target.value)}
              onSelectOption={handleMapsPlaceSelect}
              RenderOption={({ option }) => <p> {option.description} </p>}
            />
          </div>
        </div>

        {/* Map */}
        <div className="flex flex-1 w-full" style={{ height: '60vh' }}>
          <GoogleMap
            id="map"
            mapContainerStyle={{
              height: '100%',
              width: '100%',
            }}
            zoom={17}
            center={mapsCenter}
            onClick={(e) => {
              setMarker({
                lat: e.latLng?.lat() ?? marker.lat,
                lng: e.latLng?.lng() ?? marker.lng,
              });
            }}
            options={mapsRestrictions}
          >
            <MarkerF position={marker} />
          </GoogleMap>
        </div>

        {/* Confirm */}
        <div className="flex flex-1 mt-4 justify-between">
          <div className="flex flex-row items-center justify-between">
            {mapsLoading && (
              <>
                <LoadingIcon size="2rem" />
                <p className="text-sm font-semibold text-gray-900">
                  Buscando tienda receptora
                </p>
              </>
            )}
          </div>

          <PrimaryButton
            onClick={() => handleCoordsSelect(marker.lat, marker.lng)}
          >
            Seleccionar
          </PrimaryButton>
        </div>
      </Modal>

      <Modal open={openErrorModal} setOpen={setOpenErrorModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: '25rem' }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>
          <p className="mt-2 text-xl text-center text-gray-900">{errorMsg}</p>
          <div className="mt-4 flex flex-row justify-center gap-4">
            <PrimaryButton
              className="px-4"
              onClick={() => setOpenErrorModal(false)}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};
