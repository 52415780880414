import { FC } from 'react';
import { useAppSelector } from '../../store/hooks';
import { useState, useMemo, useEffect, CSSProperties } from 'react';
import {
  GuideDispatchField,
  FormSelect,
  FormText,
  GuidesDispatchTables,
  MainPage,
  PageTitle,
  PaginationFooter,
  Popover,
  PrimaryButton,
  CreateDataDispatch,
  ModalLoading,
  ConfirmModalStatus,
  DispatchPrintModal,
} from '../../components';
import {
  getGuideDispatchs,
  saveDispatchs,
} from '../../services/DispatchServices';
import {
  DispatchGuideDto,
  DispatchInterface,
  ManifestDetail,
  RouteInterface,
} from '../../interfaces';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { getRoute } from '../../services/RouteServices';
import moment from 'moment';

const CreateDispatch: FC = () => {
  const user = useAppSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [guideDisptachs, setGuideDispatchs] = useState<DispatchGuideDto[]>([]);
  const [selecteds, setSelecteds] = useState<DispatchGuideDto[]>([]);

  const [registersNumber, setRegistersNumber] = useState(10);
  const [selectNext, setSelectNext] = useState(false);
  const [save, setSave] = useState(false);

  const [sale1Dispath, setSale1Dispath] = useState('');
  const [sale2Dispath, setSale2Dispath] = useState('');
  const [sale3Dispath, setSale3Dispath] = useState('');

  const [route, setRoute] = useState('');
  const [transport, setTransport] = useState('');
  const [driver, setDriver] = useState('');

  const [listRouteDispath, setListRouteDispath] = useState<RouteInterface[]>(
    []
  );

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [reloadRegister, setReloadRegister] = useState(false);

  const [masterShipmentHeaderCode, setMasterShipmentHeaderCode] = useState('');

  const [sortBy, setSortBy] = useState({
    field: GuideDispatchField.DATECREATE,
    asc: true,
  });

  const filteredDispatch = useMemo(() => {
    setPage(0);
    //setSelecteds([]);
    const tokens = search?.toLowerCase().split(' ');
    return guideDisptachs.filter((disptach) => {
      return tokens.every((token) => {
        return (
          disptach.numGuia?.toString().toLowerCase().includes(token) ||
          disptach.origen.toLowerCase().includes(token) ||
          moment(disptach.fechaCreacion)
            ?.format('DD/MM/YYYY')
            ?.includes(token) ||
          disptach.ciudadDestino?.toString().toLowerCase().includes(token)
        );
      });
    });
  }, [guideDisptachs, search]);

  const paginatedDispatch = useMemo(() => {
    const orderedDispatch = filteredDispatch.sort((a, b) => {
      if (sortBy.field == GuideDispatchField.NUM_GUIDE) {
        return sortBy.asc
          ? a.numGuia.localeCompare(b.numGuia)
          : b.numGuia.localeCompare(a.numGuia);
      } else if (sortBy.field == GuideDispatchField.ORIGEN) {
        return sortBy.asc
          ? a.origen.localeCompare(b.origen)
          : b.origen.localeCompare(a.origen);
      } else if (sortBy.field == GuideDispatchField.DATECREATE) {
        return sortBy.asc
          ? a.fechaCreacion.localeCompare(b.fechaCreacion ?? '') ?? false
          : b.fechaCreacion.localeCompare(a.fechaCreacion ?? '') ?? false;
      } else if (sortBy.field == GuideDispatchField.SALE) {
        return sortBy.asc
          ? a?.sealNumber?.localeCompare(b?.sealNumber)
          : b?.sealNumber?.localeCompare(a?.sealNumber);
      } else if (sortBy.field == GuideDispatchField.PESO) {
        return sortBy.asc
          ? (a.peso ?? 0) - (b.peso ?? 0)
          : (b.peso ?? 0) - (a.peso ?? 0);
      }

      return 0;
    });

    const start = page * registersNumber;
    const end = start + registersNumber;

    return orderedDispatch.slice(start, end);
  }, [filteredDispatch, sortBy, page, registersNumber]);

  const handleSortByChange = (field: GuideDispatchField) => {
    if (sortBy.field == field) {
      setSortBy({ ...sortBy, asc: !sortBy.asc });
    } else {
      setSortBy({ field, asc: true });
    }
  };

  useEffect(() => {
    const customercode = '' + user?.client?.accountCode;
    const customerId = '' + user?.client?.id;
    if (!customercode || !customerId) return;

    const fetchShipments = async () => {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);

      const shipments = await getGuideDispatchs(customercode, customerId);
      var listDispatch = shipments?.model as DispatchGuideDto[];
      if (!shipments?.didError) {
        setGuideDispatchs(listDispatch);
        setLoading(false);
      } else {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + shipments?.errorMessage);
        setLoading(true);
      }
    };

    const fetchRoute = async () => {
      const shipments = await getRoute('' + user?.client?.BuCodeSource);
      var listDispatch = shipments?.model as RouteInterface[];
      if (!shipments?.didError) setListRouteDispath(listDispatch);
    };
    fetchRoute();
    fetchShipments();

    if (reloadRegister) {
      setSelectNext(false);
    }
  }, [user?.client?.id]);

  useEffect(() => {
    const customercode = '' + user?.client?.accountCode;
    const customerId = '' + user?.client?.id;
    if (!customercode || !customerId) return;

    const fetchShipments2 = async () => {
      const shipments = await getGuideDispatchs(customercode, customerId);
      if (shipments?.didError) {
        setLoadingStatus(ConfirmModalStatus.ERROR);
        setLoadingTitle('Error: ' + shipments?.errorMessage);
        setLoading(true);
      } else {
        var listDispatch = shipments?.model as DispatchGuideDto[];
        setGuideDispatchs(listDispatch);
      }
    };

    fetchShipments2();

    if (reloadRegister) {
      setSelectNext(false);
      setReloadRegister(false);
    }
  }, [reloadRegister]);

  useEffect(() => {
    if (save) {
      setLoadingStatus(ConfirmModalStatus.PROCESSING);
      setLoadingTitle('Cargando...');
      setLoading(true);
      let manifestDetail = [] as ManifestDetail[];
      selecteds?.forEach((x) => {
        for (let i = 0; i < (x.totalPiezas ?? 0); i++) {
          manifestDetail.push({
            serviceCode: x.servicioID?.toString() ?? '',
            bUCodeSource: x.origen,
            shipmentNumber: x.numGuia,
            measureUnitTypeID: null,
            pieceNumber: i + 1,
            statusID: 31,
            locationDestinationCode: x.ciudadDestino,
            locationConsigneeID: null,
            lineSeqNumber: null,
            difference: 0,
            totalPieces: x.totalPiezas ?? 0,
            heigh: 0,
            width: 0,
            lenght: 0,
            physicalWeight: x.peso / (x.totalPiezas ?? 0),
            dimensionalWeight: 0,
            barCode: undefined,
            creationUser: user?.user?.login ?? 'ccw',
          } as ManifestDetail);
        }
      });

      var destiantionRoute = listRouteDispath.find((x) => x.routeID == route);

      const array = {
        routeID: route,
        transportID: transport,
        driver1ID: driver,
        seal1: sale1Dispath,
        seal2: sale2Dispath,
        seal3: sale3Dispath,
        manifestDetail: manifestDetail as ManifestDetail[],
        statusID: 35,
        manifestTypeID: 1,
        accountID: user?.client?.id,
        accountSiteID:
          user?.client?.accountSiteId === 0
            ? undefined
            : user?.client?.accountSiteId,
        bUCodeSource: user?.client?.BuCodeSource ?? '',
        buCodeDestination: destiantionRoute?.buCodeConsignee,
        creationUser: user?.user?.login ?? 'ccw',
        shippingMethodID: 10,
        shipperName:
          destiantionRoute?.buSourceName + '-' + destiantionRoute?.buCodeSource,
        consigneeName: destiantionRoute?.buConsigneeName,
        isQRCode: false,
        masterShipmentAssociationList: [],
      } as DispatchInterface;

      const saveDispatcherApi = async () => {
        let response = await saveDispatchs(array);

        if (!response?.didError) {
          var data = response?.model;
          setMasterShipmentHeaderCode(data?.masterShipmentHeaderCode ?? '');
          setLoadingStatus(ConfirmModalStatus.PRINT);
          setLoadingTitle(
            `¡Se registro el despacho Nº ${data?.masterShipmentHeaderCode} con exito!`
          );
          setSelecteds([]);
          setLoading(true);
          setReloadRegister(true);
        } else {
          setLoadingStatus(ConfirmModalStatus.ERROR);
          setLoadingTitle(`Error: ${response.errorMessage}`);
          setLoading(true);
        }
      };
      saveDispatcherApi();
    }
  }, [save]);

  return (
    <MainPage>
      <PageTitle title="Creacion de despacho" />
      <div className="flex flex-1 flex-col">
        {!selectNext && (
          <div className="flex flex-1 justify-between gap-2 md:gap-6 flex-col-reverse md:flex-row">
            <div className="flex flex-1" style={{ maxWidth: '45rem' }}>
              <FormText
                id="search"
                name="search"
                value={search}
                placeholder="Busqueda rapida de guía..."
                containerClassname="flex flex-1"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
              <div className="flex items-center text-xs">
                <span>Mostrar</span>
                <div className="inline mx-1">
                  <FormSelect
                    id="registersNumber"
                    name="registersNumber"
                    label=""
                    selected={registersNumber}
                    options={[10, 25, 50, 100]}
                    className="!w-20 !h-8 !px-2"
                    optionString={(option) => option.toString()}
                    onSelectOption={(option) => setRegistersNumber(option)}
                  />
                </div>
                <span>registros</span>
              </div>
              <PrimaryButton
                type="submit"
                disabled={selecteds.length > 0 ? false : true}
                className="px-4"
                onClick={() => {
                  setSelectNext(true);
                }}
              >
                Siguiente{' '}
                <ChevronRightIcon
                  className="h-5 w-5 mr-0 ml-2"
                  aria-hidden="true"
                />
              </PrimaryButton>
            </div>
          </div>
        )}
        {!selectNext && (
          <div className="flex flex-1 flex-col">
            <GuidesDispatchTables
              sortBy={sortBy}
              dispatchGuides={paginatedDispatch}
              selectedGuide={selecteds}
              selectedAll={
                selecteds.length == filteredDispatch.length &&
                selecteds.length > 0
              }
              onChangeSortBy={handleSortByChange}
              onSelectGuide={(guides) => {
                if (selecteds.includes(guides)) {
                  setSelecteds(selecteds.filter((s) => s !== guides));
                } else {
                  setSelecteds([...selecteds, guides]);
                }
              }}
              onSelectAll={() => {
                if (selecteds.length == filteredDispatch.length) {
                  setSelecteds([]);
                } else {
                  setSelecteds([...filteredDispatch]);
                }
              }}
            />
            <PaginationFooter
              currentPage={page}
              itemsPerPage={registersNumber}
              totalItems={filteredDispatch.length}
              onPageChange={setPage}
            />
          </div>
        )}

        {selectNext && (
          <div className="flex flex-1 flex-col">
            <CreateDataDispatch
              setSelectNext={setSelectNext}
              setSave={setSave}
              setSale1Dispath={setSale1Dispath}
              setSale2Dispath={setSale2Dispath}
              setSale3Dispath={setSale3Dispath}
              saveDispath={save}
              setRoute={setRoute}
              setTransport={setTransport}
              setDriver={setDriver}
            ></CreateDataDispatch>
          </div>
        )}
      </div>
      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {
          setOpenPrintModal(true);
          setLoading(false);
        }}
      />
      <DispatchPrintModal
        open={openPrintModal}
        numberDispatch={masterShipmentHeaderCode}
        setOpen={setOpenPrintModal}
      />
    </MainPage>
  );
};

export default CreateDispatch;
