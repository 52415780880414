export enum ServiceEnum {
  CONTADO = '40',
  COD = '50',
  CREDITO = '60',
  STANDARD = '60',
  DOCUMENT = '61',
  INTERNATIONAL = '62',
  ECONOMIC = '64',
  NATIONAL_LOCKER = '81',
  VACIO = '0',
}
export enum ShipmentService {
  STANDARD = 60,
  DOCUMENT = 61,
  INTERNATIONAL = 62,
  ECONOMIC = 64,
  NATIONAL_LOCKER = 81,
}
