import { FC } from 'react';
import { MainPage, PageTitle } from '../components';

const Dashboard: FC = () => {
  return (
    <MainPage>
      <PageTitle title="Dashboard" />
      {/* 
      <LastPeriodStats /> */}
    </MainPage>
  );
};

export default Dashboard;
