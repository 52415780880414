import { AccountInterface } from '../interfaces';
import { dnsLookup } from '../services/utilService';
import * as Yup from 'yup';

const EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validateFullname = (fullname: string) => {
  const validCharsRegex = /^[\w\s.,'-áéíóúÁÉÍÓÚüÜ]+$/i;
  const validAtLeastTwoWordsRegex = /^.+\s.+$/;

  if (!validCharsRegex.test(fullname)) {
    return {
      error: true,
      message:
        'El nombre sólo puede contener letras, números, guiones, puntos, comas y apóstrofes',
    };
  }
  if (!validAtLeastTwoWordsRegex.test(fullname)) {
    return {
      error: true,
      message: 'El nombre debe tener al menos dos palabras',
    };
  }

  return { error: false, message: '' };
};

export const validateEmailRegex = (email: string) => {
  return EMAIL_REGX.test(email);
};

export const validateEmail = async (email: string) => {
  const emailRegexValidation = validateEmailRegex(email);
  if (!emailRegexValidation) {
    return {
      error: true,
      message: 'El correo electrónico es inválido',
    };
  }

  return { error: false, message: '' };
};

export const validateIdentityDocument = (
  identityDocument: string,
  abbreviationName: string
) => {
  if (abbreviationName == 'V-') {
    if (identityDocument.length < 6 || identityDocument.length > 8) {
      return {
        error: true,
        message: 'El número de cédula debe tener entre 6 y 8 dígitos',
      };
    }
  } else if (abbreviationName == 'E-') {
    if (identityDocument.length !== 8) {
      return {
        error: true,
        message: 'El número de cédula debe tener 8 dígitos',
      };
    }
  } else if (abbreviationName == 'P-') {
    if (identityDocument.length < 5 || identityDocument.length > 10) {
      return {
        error: true,
        message: 'El número de pasaporte debe tener entre 5 y 10 caracteres',
      };
    }
  } else if (abbreviationName == 'J-' || abbreviationName == 'G-') {
    if (identityDocument.length !== 9) {
      return {
        error: true,
        message: 'El número de RIF debe tener 9 caracteres',
      };
    }
  } else {
    return {
      error: true,
      message: 'El tipo de documento es inválido',
    };
  }

  return { error: false, message: '' };
};

export const validateAccount = async (account?: AccountInterface) => {
  if (!account) {
    return {
      error: true,
      message: 'Debe ingresar una cuenta',
    };
  }

  // Validate fullname
  const fullnameValidation = validateFullname(account.accountFullName);
  if (fullnameValidation.error) {
    return fullnameValidation;
  }

  // Validate identification document
  const identityDocumentValidation = validateIdentityDocument(
    account.identificationNumber,
    account.abbreviationName
  );
  if (identityDocumentValidation.error) {
    return identityDocumentValidation;
  }

  if (account.listAccountEmail.length == 0) {
    return {
      error: true,
      message: 'Debe ingresar al menos un correo electrónico',
    };
  }

  return { error: false, message: '' };
};

export const validateEmailDomain = async (email: string): Promise<boolean> => {
  const domain = email.split('@')[1];
  return await dnsLookup(domain);
};

export const validationSchemaAccount = Yup.object().shape({
  fullName: Yup.string().required('Este campo es requerido'),
  abbreviationName: Yup.object().required('Este campo es requerido'),
  identificationNumber: Yup.string().required('Este campo es requerido'),
  phoneNumber: Yup.string()
    .required('Este campo es requerido')
    .test({
      name: 'number',
      message: 'Debe tener un maximo de 7 caracteres',
      test: (number, parent) => {
        if (parent.parent.codePhoneNumber !== 'FIJO' && number.length > 7)
          return false;

        return true;
      },
    })
    .test({
      name: 'number',
      message: 'Debe tener un maximo de 10 caracteres',
      test: (number, parent) => {
        if (parent.parent.codePhoneNumber === 'FIJO' && number.length > 10)
          return false;

        return true;
      },
    }),
  codePhoneNumber: Yup.string().required('Este campo es requerido'),
  cellPhoneNumber: Yup.string()
    .test({
      name: 'number',
      message: 'Debe tener un maximo de 7 caracteres',
      test: (number, parent) => {
        if (
          parent.parent.codecellNumber !== 'FIJO' &&
          number !== undefined &&
          number?.length > 7
        )
          return false;

        return true;
      },
    })
    .test({
      name: 'number',
      message: 'Debe tener un maximo de 10 caracteres',
      test: (number, parent) => {
        if (
          parent.parent.codecellNumber === 'FIJO' &&
          number !== undefined &&
          number?.length > 10
        )
          return false;

        return true;
      },
    }),
  email: Yup.string()
    .email('Correo invalido. Ejemplo: tealca@tealca.com')
    .required('Este campo es requerido'),
});
