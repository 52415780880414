import { CategoryDto } from '../Shipment/CategoryDto';

export interface RangeDeclareDto {
  rangeID: number;
  minWeight: number;
  maxWeight: number;
  minDeclareValue: number;
  maxDeclareValue: number;
}

export interface GeneralAppDataDto {
  applicationName: string;
  applicationID: number;
  applicationDescription: string;
  applicationCode: string;
  rangeDeclareList: RangeDeclareDto[];
  exchangeRate: ExchangeRateDto;
  volumenMin: GeneralParams[];
  densityMax: GeneralParams[];
  highDeclaredValue: GeneralParams[];
  shippingCategoryList: CategoryDto[];
}

export interface GeneralParams {
  valueGeneral: string;
  valueMin: string;
  valueMax: string;
  fromDate: string | null;
  toDate: string | null;
}

export interface ItemInterface {
  id: string;
  code: string;
  name: string;
  order: number;
  mandatory: boolean;
  couponID?: string;
  isItemBase: boolean;
  isItemShipment?: boolean;
  rate: {
    value: number;
    iva: number;
    ipostel: number;
    isPercentage: boolean;
    distance: number;
    tierCode: string;
    tierName: string;
  };
}

export const discountItem: ItemInterface = {
  id: '31',
  code: '0310',
  name: 'Bonificación a Cliente',
  order: 0,
  isItemBase: false,
  mandatory: false,
  rate: {
    value: 0,
    isPercentage: true,
    iva: 0,
    ipostel: 0,
    distance: 0,
    tierCode: '',
    tierName: '',
  },
};

export interface ExchangeRateDto {
  countryID: number;
  currencyLocalID: number;
  exchangeCurrencyID: number;
  valueDate: Date;
  exchangeRate: number;
}

export interface GetShipmentItemsRequestDTO {
  CountryID: number;
  Weight: number;
  DeclaratedValue: number;
  Distance: number;
  CurrencyID: number;
  ApplicationID: number;
  ShipperAccountID?: string;
  ConsigneeAccountID?: string;
  AccountBillToID?: string;
  AccountSiteID?: number;
  ServiceID?: number;
  PaymentModeID?: number;
  DeliveryTypeID?: number;
  SRM?: boolean;
  TierID?: number;
  MeasureUnitTypeID?: number;
  ShippingMethodID?: number;
  PackageTypeID?: number;
  CategoryID?: number;
  SalesDate?: string;
  BUCodeSource?: string;
  BUCodeConsignee?: string;
  IsToPickup?: boolean;
}
