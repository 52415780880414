import { FormText } from '../FormFields/FormText';
import { useAppSelector } from '../../store/hooks';
import { FormSelect } from '../FormFields/FormSelect';
import {
  AccountInterface,
  BusinessType,
  BusinessUnitInterface,
  DeliveryEnum,
  DestinationInterface,
  LocationInterface,
  TaxIdentificationTypeInterface,
} from '../../interfaces';
import { FormTextArea } from '../FormFields/FormTextArea';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { AccountSearchFavoriteHistoric } from '../Account/AccountSearch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getAccount,
  getBusinessUnitByTerritory,
  saveAccountService,
} from '../../services';
import { GeneralTypePhoneServices } from '../../services/GeneralServices';
import { GeneralTypeInterface } from '../../interfaces/Dto/GeneralTypeInterface';
import {
  filterBusinessUnit,
  filterGeneral,
  filterCity,
} from '../../utils/filters';
import {
  BusinessUnitOption,
  CityOption,
  GenericoOption,
} from '../FormFields/FormSearchOptions';
import { FormSearch } from '../FormFields/FormSearch';
import { FormRadioGroup } from '../FormFields/FormRadioGroup';
import { AddressSearch } from '../FormFields/AddressSearch';
import { AutocompleteRegion } from '../FormFields/FormMaps';
import { obtenerCodigoArea, obtenerNumero } from '../../utils/format';
import { PrimaryButton } from '../Atoms/Buttons';
import { PlusIcon } from '@heroicons/react/24/outline';
import * as Yup from 'yup';
import { validationSchemaAccount } from '../../utils/accountValidations';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import ModalLoading from '../Atoms/ModalLoading';

export interface ConsigneeFormValuesV2 {
  abbreviationName: TaxIdentificationTypeInterface;
  identificationNumber: string;
  fullName: string;
  codePhoneNumber?: string;
  phoneId: string;
  phoneTypeId: string;
  phoneNumber: string;
  acceptsSMS: boolean;
  codecellNumber?: string;
  cellPhoneNumber: string;
  emailId: string;
  email: string;
  city: string;
  cityCode?: string;
  postalCode: string;
  address: string;
  businessUnit?: BusinessUnitInterface;
  accountSelected?: AccountInterface;
  ShipmentType?: DeliveryEnum;
  reference: string;
  reset?: boolean;
  id: string;
}

interface ConsigneeFieldV2 {
  consignee: ConsigneeFormValuesV2;
  shippingLocation?: LocationInterface;
}
interface ConsigneeFormPropsV2<T> {
  formik: FormikProps<T & ConsigneeFieldV2>;
}

export const ConsigneeFormV2 = <T extends any>({
  formik,
}: ConsigneeFormPropsV2<T>) => {
  const cities = useAppSelector((state) => state.inmutable.cities);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const clientId = useAppSelector((state) => state.user.client?.id);

  const errors = formik.errors.consignee as
    | FormikErrors<ConsigneeFormValuesV2>
    | undefined;

  const touched = formik.touched.consignee as
    | FormikTouched<ConsigneeFormValuesV2>
    | undefined;

  const [phonePreSearch, setPhonePreSearch] = useState('');
  const [cellPreSearch, setCellPreSearch] = useState('');
  const [businessUnitSearch, setBusinessUnitSearch] = useState('');
  const [citySearch, setCitySearch] = useState('');

  const [titleShipment] = useState('Oficina que entrega');
  const [selectDestination, setselectDestination] = useState<boolean>(false);
  const [phonePrefixList, setPhonePrefixList] = useState<string[]>([]);
  const [disabledIdentification, setDisabledIdentification] =
    useState<boolean>(false);
  const stores = useAppSelector((state) => state.inmutable.businessUnits);

  const updatePhoneSearch = useCallback(() => {
    if (formik.values.consignee.codePhoneNumber) {
      setPhonePreSearch(formik.values.consignee.codePhoneNumber);
    } else {
      setPhonePreSearch('');
    }
  }, [formik.values.consignee.codePhoneNumber]);

  const updateCellSearch = useCallback(() => {
    if (!!formik.values.consignee.codecellNumber) {
      setCellPreSearch(formik.values.consignee.codecellNumber);
    } else {
      setCellPreSearch('');
    }
  }, [formik.values.consignee.codecellNumber]);

  useEffect(() => {
    if (formik.values.consignee.reset) setselectDestination(false);
    else setselectDestination(true);
  }, [formik.values.consignee.reset]);

  useEffect(() => {
    if (formik.values.consignee.businessUnit === undefined) {
      setBusinessUnitSearch('');
      setCitySearch('');
    }
  }, [formik.values.consignee.businessUnit]);

  const updateBusinessUnitSearch = (
    location?: LocationInterface, // Parámetro obligatorio primero
    businessUnit?: BusinessUnitInterface, // Parámetro opcional después
    Address?: string // Parámetro opcional después
  ) => {
    if (!!businessUnit) {
      const locationCode = businessUnits.find(
        (bu) => bu.buCode === businessUnit?.buCode
      )?.location.locationCode;

      const fiendCities = cities.find((c) => c.locationCode === locationCode);

      if (formik.values.consignee?.ShipmentType !== DeliveryEnum.DELIVERY) {
        formik.setValues({
          ...formik.values,
          shippingLocation: businessUnit?.location,
          businessUnit: businessUnit,
          consignee: {
            ...formik.values.consignee,
            businessUnit: businessUnit,
            address: businessUnit?.location?.address ?? '',
            cityCode: fiendCities?.locationCode ?? '',
            city: fiendCities?.locationName ?? '',
          },
        });
      } else {
        formik.setValues({
          ...formik.values,
          shippingLocation: location,
          businessUnit: businessUnit,
          consignee: {
            ...formik.values.consignee,
            businessUnit: businessUnit,
            address: Address ?? '',
            cityCode: fiendCities?.locationCode ?? '',
            city: fiendCities?.locationName ?? '',
          },
        });
      }

      setBusinessUnitSearch(
        businessUnit?.buCode + ' - ' + businessUnit?.location?.name
      );
    } else {
      setBusinessUnitSearch('');
      setCitySearch('');
    }
  };

  const updateCitySearch = useCallback(() => {
    if (!!formik.values.consignee.city) {
      setCitySearch(formik.values.consignee.city);
    } else {
      setCitySearch('');
    }
  }, [formik.values.consignee.city]);

  const getPhonePrefixList = async () => {
    const phonetype = await GeneralTypePhoneServices('236');
    if (!!phonetype.didError || !phonetype.model) return;

    const emptyPhonePrefix = { value: 'FIJO', text: 'FIJO' };
    let phonetypeList = phonetype.model as unknown as GeneralTypeInterface[];
    phonetypeList.push(emptyPhonePrefix);

    setPhonePrefixList(
      (phonetypeList as unknown as GeneralTypeInterface[]).map(
        (phone) => phone.value
      )
    );
  };

  useEffect(() => {
    //getLocations();
    getPhonePrefixList();
  }, [clientId]);

  const validate = () => {
    try {
      validationSchemaAccount.validateSync(formik.values.consignee, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors: FormikErrors<T & ConsigneeFieldV2> = error.inner.reduce(
          (errors, err) => {
            return { ...errors, [err.path!]: err.message };
          },
          {}
        );
        formik.setFormikState({
          ...formik,
          errors: { ...formik.errors, consignee: errors },
          touched: {
            ...formik.touched,
            consignee: {
              abbreviationName: true,
              identificationNumber: true,
              fullName: true,
              codePhoneNumber: true,
              phoneId: true,
              phoneTypeId: true,
              phoneNumber: true,
              acceptsSMS: true,
              codecellNumber: true,
              cellPhoneNumber: true,
              emailId: true,
              email: true,
              city: true,
              cityCode: true,
              id: true,
            },
          },
        });
      }
      return false;
    }
  };
  const user = useAppSelector((state) => state.user)!;

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const saveAccount = async () => {
    if (!validate()) return;
    const listAccountPhone = [
      {
        countryId: '236',
        acceptsSMS: false,
        countryPhoneAccessCode: '+58',
        phoneTypeId: '1',
        phoneNumber:
          (formik.values.consignee.codePhoneNumber === 'FIJO'
            ? ''
            : formik.values.consignee.codePhoneNumber) +
          '' +
          formik.values.consignee.phoneNumber,
      },
    ];
    if (!!formik.values.consignee.cellPhoneNumber) {
      listAccountPhone.push({
        countryId: '236',
        acceptsSMS: formik.values.consignee.acceptsSMS,
        countryPhoneAccessCode: '+58',
        phoneTypeId: '2',
        phoneNumber:
          (formik.values.consignee.codecellNumber === 'FIJO'
            ? ''
            : formik.values.consignee.codecellNumber) +
          ' ' +
          formik.values.consignee.cellPhoneNumber,
      });
    }
    let values = formik.values;

    const account = {
      id: '',
      accountCode: '',
      accountTypeID: 3,
      agreementId: 0,
      countryID: 236,
      cityCode: values.consignee.cityCode,
      taxIdentificationTypeID:
        values.consignee.abbreviationName.taxIdentificationTypeId,
      abbreviationName:
        values.consignee.abbreviationName.taxIdentificationTypeCode,
      identificationNumber: values.consignee.identificationNumber,
      accountFullName: values.consignee.fullName,
      fiscalAddress: values.consignee.address,
      reference: values.consignee.reference,
      listAccountEmail: [
        {
          email: values.consignee.email,
          accountId: undefined,
          emailTypeId: '1',
          emailID: undefined,
        },
      ],
      listAccountPhone,
      creationUser: user.user?.login ?? undefined,
      creationDate: new Date().toISOString(),
      listAuthorizingAccount: [],
    } as AccountInterface;

    setLoadingStatus(ConfirmModalStatus.PROCESSING);
    setLoadingTitle('Cargando...');
    setLoading(true);

    const accountAuth = await saveAccountService(account);

    if (accountAuth.didError) {
      setLoadingStatus(ConfirmModalStatus.ERROR);
      if (accountAuth.statusId === 403 || accountAuth.statusId === 401) {
        setLoadingTitle('Error: Contacte a soporte. No tiene permiso');
      } else if (
        accountAuth?.errorMessage?.includes(
          'Número de Identificación ya existe'
        )
      ) {
        setLoadingTitle('Error: ' + accountAuth?.errorMessage);
        formik.setFieldError(
          'consignee.identificationNumber',
          'Número de Identificación ya existe'
        );
      }
      setLoading(true);
    } else {
      var firtClient = accountAuth.model;
      formik.setValues({
        ...formik.values,
        consignee: {
          ...formik.values.consignee,
          id: firtClient?.id ?? '',
          reset: false,
        },
      });
      setLoadingStatus(ConfirmModalStatus.SUCCESS);
      setLoadingTitle('Se registro el destinatario');
    }

    return;
  };

  const businessUnitOptions = useMemo(() => {
    const business = businessUnits.filter(
      (businessUnit) =>
        //businessUnit.code !== '8110' &&
        businessUnit.buCode !== 'KK' &&
        businessUnit.buCode !== 'NN' &&
        //businessUnit.code !== 'MAD' &&
        //businessUnit.code !== 'MIA' &&
        //businessUnit.code !== 'PTY' &&
        !businessUnit.isLocked &&
        (businessUnit.buTypeID === BusinessType.EXTERNAL ||
          businessUnit.buTypeID === BusinessType.INTERNAL)
    );
    // Delete duplicates by id
    const uniqueIds = new Set();
    const result: BusinessUnitInterface[] = [];
    business.forEach((businessUnit, index) => {
      if (!uniqueIds.has(businessUnit.buid)) {
        uniqueIds.add(businessUnit.buid);
        result.push(businessUnit);
      }
    });

    result.sort((a, b) => {
      const tokens = businessUnitSearch.split(' ');

      const aMatch = tokens.some((token) => token === a.buCode);
      const bMatch = tokens.some((token) => token === b.buCode);

      if (aMatch && !bMatch) return -1;
      if (!aMatch && bMatch) return 1;
      return 0;
    });

    return result;
  }, [businessUnits, user.businessUnit, businessUnitSearch]);

  return (
    <div className="flex h-full flex-col gap-1">
      <h4 className="text-sm text-main-500 font-bold">
        Libreta de Destinatarios:
      </h4>

      <AccountSearchFavoriteHistoric
        title=""
        selected={formik.values.consignee.accountSelected}
        error={
          touched?.accountSelected && errors?.accountSelected
            ? errors?.accountSelected
            : undefined
        }
        placeholder="Ingrese destinatario por Identificación, Nombre o telefono"
        favoriteLocations={[]}
        historicLocations={[]}
        onSelectClient={async (client) => {
          setselectDestination(true);
          setDisabledIdentification(true);
          const buClient = businessUnits.find(
            (x) => x?.buCode + ' - ' + x?.location?.name === businessUnitSearch
          );
          formik.setValues({
            ...formik.values,
            consignee: {
              ...formik.values.consignee,
              id: client.id,
              abbreviationName: identificationTypes.find(
                (t) => t.abbreviationName === client?.abreviationName
              )!,
              identificationNumber: client.identificationNumber,
              //address: client.fiscalAddress ?? '',
              fullName: client.accountFullName ?? '',
              phoneId: client.listAccountPhone[0]?.phoneID?.toString() ?? '',
              phoneTypeId: client.listAccountPhone[0]?.phoneTypeId ?? '',
              phoneNumber:
                obtenerNumero(client.listAccountPhone[0]?.phoneNumber) ?? '',
              emailId: client.listAccountEmail[0]?.emailID ?? '',
              email: client.listAccountEmail[0]?.email ?? '',
              codePhoneNumber:
                obtenerCodigoArea(client.listAccountPhone[0]?.phoneNumber) ??
                '',
              businessUnit: buClient,
              accountSelected: client,
              reset: false,
            },
          });
        }}
        onSelectDestination={async (destination) => {
          const consigneeFind =
            (await getAccount(destination.account.id)) ?? undefined;

          let buWork: BusinessUnitInterface | undefined;
          let address = '';
          let shippingLocation = destination.location;

          if (!destination.isHomeDelivery) {
            buWork = businessUnits.find(
              (bu) =>
                bu.buCode === destination.businessUnit.buCode ||
                bu.buCode === destination.store?.code
            )!;
            shippingLocation = buWork.location;
            address = buWork.location.address ?? '';
          } else {
            buWork = await getBusinessUnitByTerritory(
              destination.location.coordinates.lng,
              destination.location.coordinates.lat
            ).then((response: any) => response.model!);
            address = destination.location?.address ?? '';
          }
          setBusinessUnitSearch(
            buWork?.buCode + ' - ' + buWork?.location?.name
          );

          const locationCode = businessUnits.find(
            (bu) => bu.buCode === buWork?.buCode
          )?.location.locationCode;

          const fiendCities = cities.find(
            (c) => c.locationCode === locationCode
          );

          formik.setValues({
            ...formik.values,
            shippingLocation: shippingLocation,
            businessUnit: buWork,
            consignee: {
              ...formik.values.consignee,
              id: destination.account.id,
              businessUnit: buWork,
              address: address,
              cityCode: fiendCities?.locationCode ?? '',
              city: fiendCities?.locationName ?? '',
              abbreviationName: identificationTypes.find(
                (t) =>
                  t.abbreviationName === destination.account?.abbreviationName
              )!,
              identificationNumber: destination.account.identificationNumber,
              fullName: destination.account.accountFullName ?? '',
              codePhoneNumber:
                obtenerCodigoArea(
                  consigneeFind?.listAccountPhone[0]?.phoneNumber ?? ''
                ) ?? '',
              phoneNumber:
                obtenerNumero(
                  consigneeFind?.listAccountPhone[0]?.phoneNumber ?? ''
                ) ?? '',
              email: consigneeFind?.listAccountEmail[0]?.email ?? '',
              postalCode: destination.location.postalCode ?? '',
              accountSelected: destination.account,
              reset: false,
              emailId: consigneeFind?.listAccountEmail[0]?.emailID ?? '',
              phoneId:
                consigneeFind?.listAccountPhone[0]?.phoneID?.toString() ?? '',
              phoneTypeId:
                consigneeFind?.listAccountPhone[0]?.phoneTypeId?.toString() ??
                '',
              ShipmentType: destination.isHomeDelivery
                ? DeliveryEnum.DELIVERY
                : DeliveryEnum.OFFICE,
            },
          });
          setselectDestination(true);
          setDisabledIdentification(true);
        }}
        openCreationModal={() => {
          formik.setValues({
            ...formik.values,
            consignee: {
              ...formik.values.consignee,
              abbreviationName: identificationTypes.find(
                (t) => t.abbreviationName === 'V-'
              )!,
              identificationNumber: '',
              codePhoneNumber: '',
              address: '',
              fullName: '',
              phoneNumber: '',
              email: '',
              id: '',
              businessUnit: undefined,
              accountSelected: {} as AccountInterface,
              reset: false,
            },
          });
          setselectDestination(true);
          setDisabledIdentification(false);
        }}
      />

      {selectDestination === true ? (
        <div className="flex h-full flex-col gap-1 mt-1">
          <hr className="my-3" />
          {(formik.values.consignee.id === undefined ||
            formik.values.consignee.id.trim() === '') && (
            <h4 className="text-sm text-main-500 gap-2 font-bold mb-3 mt-1">
              Creacion del Destinatario:
            </h4>
          )}

          {formik.values.consignee.id !== '' && (
            <h4 className="text-sm text-main-500 gap-2 font-bold mb-3 mt-1">
              Detalles del Destinatario:
            </h4>
          )}
          <FormSelect
            autoComplete="off"
            id="consignee.abbreviationName"
            name="consignee.abbreviationName"
            label="Tipo de Documento"
            selected={formik.values.consignee.abbreviationName}
            options={identificationTypes}
            error={
              touched?.abbreviationName && errors?.abbreviationName
                ? errors?.abbreviationName
                : undefined
            }
            onBlur={formik.handleBlur}
            className="!h-7"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            optionString={(option) => option.abbreviationName.slice(0, 1)}
            onSelectOption={(option) =>
              formik.setFieldValue('consignee.abbreviationName', option)
            }
            disabled={disabledIdentification}
          />

          <FormText
            id="consignee.identificationNumber"
            name="consignee.identificationNumber"
            label="Nro. Documento"
            value={formik.values.consignee.identificationNumber}
            error={
              touched?.identificationNumber && errors?.identificationNumber
                ? errors?.identificationNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              if (
                formik.values.consignee.abbreviationName.abbreviationName !==
                'P-'
              ) {
                // Only numbers
                re = /^[0-9\b]{0,10}$/;
              } else {
                // Only numbers and letters
                re = /^[a-zA-Z0-9\b]{0,10}$/;
              }

              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '10rem' }}
            disabled={disabledIdentification}
          />

          <FormText
            id="consignee.fullName"
            name="consignee.fullName"
            label="Nombre"
            value={formik.values.consignee.fullName}
            error={
              touched?.fullName && errors?.fullName
                ? errors?.fullName
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />
          <FormSearch
            id="consignee.codePhoneNumber"
            name="consignee.codePhoneNumber"
            label="Operadora"
            value={phonePreSearch}
            options={phonePrefixList}
            unselect={!!formik.values.consignee.codePhoneNumber}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.codePhoneNumber && errors?.codePhoneNumber
                ? errors?.codePhoneNumber
                : undefined
            }
            onBlur={(e) => {
              // Usar un pequeño retraso para permitir la selección de la opción
              setTimeout(() => {
                if (
                  !e.relatedTarget ||
                  !e.relatedTarget.classList.contains('option-class')
                ) {
                  formik.handleBlur(e);
                }
              }, 200); // Ajusta el tiempo según sea necesario
            }}
            onChange={(e) => setPhonePreSearch(e.target.value)}
            onSelectOption={(option) => {
              formik.setFieldValue('consignee.codePhoneNumber', option);
              setPhonePreSearch(option ?? ''); // Actualiza phonePreSearch al seleccionar una opción
            }}
            onChangeFocus={(focus) => !focus && updatePhoneSearch()}
            style={{ maxWidth: '15rem' }}
            onFilter={filterGeneral}
            RenderOption={({ option }) => <GenericoOption text={option} />}
          />

          <FormText
            id="consignee.phoneNumber"
            name="consignee.phoneNumber"
            label="Teléfono"
            value={formik.values.consignee.phoneNumber}
            error={
              touched?.phoneNumber && errors?.phoneNumber
                ? errors?.phoneNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,10}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            maxLength={
              formik.values.consignee.codePhoneNumber !== 'FIJO' ? 7 : 10
            }
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          <FormSearch
            id="consignee.codecellNumber"
            name="consignee.codecellNumber"
            label="Operadora"
            value={cellPreSearch}
            options={phonePrefixList}
            unselect={!!formik.values.consignee.codecellNumber}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            error={
              touched?.codecellNumber && errors?.codecellNumber
                ? errors?.codecellNumber
                : undefined
            }
            onBlur={(e) => {
              // Usar un pequeño retraso para permitir la selección de la opción
              setTimeout(() => {
                if (
                  !e.relatedTarget ||
                  !e.relatedTarget.classList.contains('option-class')
                ) {
                  formik.handleBlur(e);
                }
              }, 200); // Ajusta el tiempo según sea necesario
            }}
            onChange={(e) => setCellPreSearch(e.target.value)}
            onSelectOption={(option) =>
              formik.setFieldValue('consignee.codecellNumber', option)
            }
            onChangeFocus={(focus) => !focus && updateCellSearch()}
            style={{ maxWidth: '15rem' }}
            onFilter={filterGeneral}
            RenderOption={({ option }) => <GenericoOption text={option} />}
          />

          <FormText
            id="consignee.cellPhoneNumber"
            name="consignee.cellPhoneNumber"
            label="Teléfono 2"
            value={formik.values.consignee.cellPhoneNumber}
            error={
              touched?.cellPhoneNumber && errors?.cellPhoneNumber
                ? errors?.cellPhoneNumber
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,10}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            maxLength={
              formik.values.consignee.codecellNumber !== 'FIJO' ? 7 : 10
            }
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          <FormText
            id="consignee.email"
            name="consignee.email"
            label="Correo"
            value={formik.values.consignee.email}
            error={touched?.email && errors?.email ? errors?.email : undefined}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            labelClassname="!text-xs"
            labelContainerClassname="flex sm:!justify-end sm:w-32"
            containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
            style={{ maxWidth: '15rem' }}
          />

          {(formik.values.consignee.id === undefined ||
            formik.values.consignee.id.trim() === '') && (
            <div className="flex justify-end items-end gap-12">
              <PrimaryButton
                type="button"
                onClick={() => saveAccount()}
                className="flex items-center gap-1"
              >
                Guardar
                <PlusIcon className="h-4 w-4" />
              </PrimaryButton>
            </div>
          )}

          {formik.values.consignee.id !== '' && (
            <>
              <hr className="my-3" />
              <h4 className="text-sm text-main-500 gap-2 font-bold mb-3 mt-1">
                Detalle del Destino:
              </h4>
              <FormRadioGroup
                id="consignee.ShipmentType"
                name="consignee.ShipmentType"
                label="Tipo de retiro"
                selected={formik.values.consignee.ShipmentType}
                labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                className="flex flex-1 sm:ml-0 sm:gap-5 sm:items-center flex-col sm:flex-row"
                wrapperClassName="flex !flex-row ml-4"
                optionsClassName="text-xs !ml-1"
                options={[
                  { value: DeliveryEnum.OFFICE, name: 'Tienda' },
                  { value: DeliveryEnum.DELIVERY, name: 'Domicilio' },
                ]}
                onSelectOption={(opt) => {
                  formik.setFieldValue('consignee.ShipmentType', opt);
                  //setSelectShipmentSearch(formik.values.consignee?.ShipmentType ?? DeliveryEnum.OFFICE) ;
                }}
              />

              <div className="lex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row ">
                {formik.values.consignee?.ShipmentType?.toString() ===
                  DeliveryEnum.DELIVERY?.toString() && (
                  <div className="flex gap-8">
                    <div className="hidden sm:block sm:w-32" />

                    <div className="flex-1 max-w-[25rem]">
                      <AddressSearch
                        stores={stores}
                        selected={formik.values.shippingLocation}
                        region={AutocompleteRegion.TEALCA}
                        error={
                          formik.touched?.shippingLocation &&
                          formik.errors?.shippingLocation
                            ? formik.errors?.shippingLocation
                            : undefined
                        }
                        setSelectedLocation={(location, store) => {
                          location.address = location?.address ?? '';

                          updateBusinessUnitSearch(
                            location,
                            store,
                            location?.address ?? ''
                          );
                        }}
                      />
                    </div>
                  </div>
                )}

                {formik.values.consignee?.ShipmentType?.toString() ===
                  DeliveryEnum.DELIVERY?.toString() && (
                  <FormText
                    value={formik.values.consignee?.reference ?? ''}
                    id="consignee.reference"
                    name="consignee.reference"
                    label="Punto de Referencia"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="!h-8 mt-1"
                    labelClassname="!text-xs"
                    labelContainerClassname="flex sm:!justify-end sm:w-32"
                    containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                    style={{ maxWidth: '15rem' }}
                  />
                )}
              </div>

              <FormSearch
                id="consignee.businessUnit"
                name="consignee.businessUnit"
                label={titleShipment}
                placeholder="Ingresa la tienda"
                value={businessUnitSearch}
                options={businessUnitOptions}
                unselect={!!(businessUnitSearch.length > 0)}
                className="!h-8"
                disabled={
                  formik.values.consignee?.ShipmentType?.toString() ===
                  DeliveryEnum.DELIVERY?.toString()
                    ? true
                    : false
                }
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                error={
                  touched?.businessUnit && errors?.businessUnit
                    ? errors?.businessUnit
                    : undefined
                }
                onBlur={(e) => {
                  // Usar un pequeño retraso para permitir la selección de la opción
                  setTimeout(() => {
                    if (
                      !e.relatedTarget ||
                      !e.relatedTarget.classList.contains('option-class')
                    ) {
                      formik.handleBlur(e);
                    }
                  }, 200); // Ajusta el tiempo según sea necesario
                }}
                onChange={(e) => setBusinessUnitSearch(e.target.value)}
                //onChangeFocus={(focus) => !focus && updateBusinessUnitSearch()}
                onSelectOption={(option) => {
                  updateBusinessUnitSearch(
                    option?.location as LocationInterface,
                    option
                  );
                  //formik.setFieldValue('consignee.businessUnit', option);
                }}
                onFilter={filterBusinessUnit}
                style={{ maxWidth: '20rem' }}
                RenderOption={({ option }) => (
                  <BusinessUnitOption {...option} />
                )}
              />

              <FormSearch
                id="consignee.city"
                name="consignee.city"
                label="Ciudad Destino"
                value={citySearch}
                disabled={true}
                options={cities}
                unselect={!!formik.values.consignee.city}
                className="!h-8"
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                error={touched?.city && errors?.city ? errors?.city : undefined}
                onBlur={formik.handleBlur}
                onChange={(e) => setCitySearch(e.target.value)}
                onChangeFocus={(focus) => !focus && updateCitySearch()}
                onSelectOption={(option) => {
                  formik.setFieldValue(
                    'consignee.cityCode',
                    option?.locationCode
                  );
                  formik.setFieldValue('consignee.city', option?.locationName);
                }}
                onFilter={filterCity}
                style={{ maxWidth: '20rem' }}
                RenderOption={({ option }) => <CityOption {...option} />}
              />
              <FormTextArea
                id="consignee.address"
                name="consignee.address"
                label="Dirección"
                value={formik.values.consignee.address}
                error={
                  touched?.address && errors?.address
                    ? errors?.address
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                labelClassname="!text-xs"
                labelContainerClassname="flex sm:!justify-end sm:w-32 sm:mt-2"
                containerClassname="flex flex-1 sm:ml-0 sm:gap-8 flex-col sm:flex-row"
                disabled={true}
              />
            </>
          )}

          <ModalLoading
            open={loading}
            title={loadingTitle}
            status={loadingStatus}
            setOpen={setLoading}
            onPrint={() => {}}
          />
        </div>
      ) : (
        <div /* Este es el div 2 */ className="red2" />
      )}
    </div>
  );
};
