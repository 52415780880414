import { FC } from 'react';
import { ShipmentTracking } from '../../interfaces';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TimelineItem: FC<ShipmentTracking & { isLastItem?: boolean }> = ({
  status,
  description,
  date,
  isLastItem,
}) => {
  return (
    <div className="group relative py-3 pl-5 sm:pl-32">
      <div className="mb-1 flex flex-col items-start before:absolute before:left-1 before:h-full before:-translate-x-1/2 before:translate-y-3 before:self-start before:bg-slate-300 before:px-px after:absolute after:left-2 after:box-content after:h-2 after:w-2 after:-translate-x-1/2 after:translate-y-1.5 after:rounded-full after:border-4 after:border-slate-50 after:bg-main-500 group-last:before:hidden sm:flex-row sm:before:left-0 sm:before:ml-[6.5rem] sm:after:left-0 sm:after:ml-[6.5rem]">
        <div className="text-xs font-bold text-slate-800">{status}</div>
      </div>

      {/* Fecha con efecto de animación */}
      <div className="text-main-500 text-[10px]">{date}</div>

      <div className="text-slate-500">{description}</div>

      {/* Flecha encima del círculo (excepto en el último ítem) */}
      {!isLastItem && (
        <div className="absolute left-1/2 top-[calc(50%-30px)] h-0 w-0 -translate-x-1/2 transform border-x-8 border-t-8 border-x-transparent border-t-slate-300 sm:left-0 sm:ml-[6.5rem]  transition-transform duration-300 group-hover:translate-y-1"></div>
      )}
    </div>
  );
};
