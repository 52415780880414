import { FC, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Popover } from '../Atoms/Popover';
import { ShipmentInterface, ShipmentTracking } from '../../interfaces';
import { FormCheckbox } from '../FormFields/FormCheckbox';
import { ShipmentDetailsModal } from './ShipmentDetailsModal';
import {
  PrinterIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { ShipmentPrintModal } from '../ShipmentV2/ShipmentPrintModal';
import { ShipmentPiecesPrintModal } from '../ShipmentV2/ShipmentPrintPiecesModal';
import { PrintLabel } from '../../services/printerServices';
import { mapServiceToEnum } from '../../utils/mappers';
import { ShipmentService } from '../../interfaces/Services/ServicesEnum';
import { getShipmentTracking } from '../../services';

export enum ShipmentField {
  SHIPMENT,
  PIECES,
  WEIGHT,
  VOLUME_CM3,
  VOLUME_CU3,
  ALIAS,
  PHONE,
  CITY,
  DATE,
  TRACKING,
}

const TableOptionsButton = (
  <div className="flex w-8 h-8 items-center justify-center">
    <EllipsisVerticalIcon className="w-5 h-5" />
  </div>
);

interface ShipmentTableProps {
  selectedAll: boolean;
  shipments: ShipmentInterface[];
  selectedShipments: ShipmentInterface[];
  sortBy: { field: ShipmentField; asc: boolean };
  onChangeSortBy: (field: ShipmentField) => void;
  onSelectShipment: (shipment: ShipmentInterface) => void;
  onSelectAll: () => void;
}
export const ShipmentTable: FC<ShipmentTableProps> = ({
  selectedAll,
  shipments,
  selectedShipments,
  sortBy,
  onChangeSortBy,
  onSelectShipment,
  onSelectAll,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<ShipmentInterface>();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openPrintHelpModal, setOpenPrintHelpModal] = useState(false);
  const [openPrintPiecesModal, setOpenPrintPiecesModal] = useState(false);
  const [selectTypePiecesPrint, setSelectTypePiecesPrint] = useState(2);
  const [tracking, setTracking] = useState<ShipmentTracking[]>([]);
  const [loadingtracking, setLoadingtracking] = useState(false);

  useEffect(() => {
    if (openPrintHelpModal) printLabelApi();

    setOpenPrintHelpModal(false);
  }, [openPrintHelpModal]);

  const printLabelApi = async () => {
    await PrintLabel(
      selected?.number ?? 0,
      parseInt(selected?.service ?? '60'),
      selected?.businessUnitOrigin?.buCode ?? '',
      []
    );
  };

  const getTracking = async (shipment: ShipmentInterface) => {
    const service = shipment?.service;
    const buSource = shipment?.businessUnitOrigin.buCode;
    const shipmentNumber = shipment?.number;
    setTracking([]);
    if (!shipmentNumber || !service || !buSource) return;
    const serviceEnum = mapServiceToEnum(service) ?? ShipmentService.STANDARD;
    setLoadingtracking(true);
    const tracking = await getShipmentTracking(
      shipmentNumber?.toString(),
      serviceEnum,
      buSource
    ).finally(() => {
      setLoadingtracking(false);
    });

    if (!tracking) return;

    setTracking(
      tracking.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
    );
  };

  return (
    <div className="relative w-full table-fix-head mt-4 h-95   ">
      <table className="table-auto w-full text-gray-600 text-xs text-left border border-gray-300">
        <thead className="select-none font-semibold border-b border-gray-300 bg-gray-50">
          <tr>
            <th className="pl-2 py-2">
              <FormCheckbox
                id="selectAll"
                name="selectAll"
                label=""
                onChange={onSelectAll}
                checked={selectedAll}
              />
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(ShipmentField.SHIPMENT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Guía</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.SHIPMENT && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(ShipmentField.PIECES)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Piezas</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.PIECES && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2 truncate"
              onClick={() => onChangeSortBy(ShipmentField.WEIGHT)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Peso Kg</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.WEIGHT && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(ShipmentField.VOLUME_CM3)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Volumen (cm3)</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.VOLUME_CM3 && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-2 py-2"
              onClick={() => onChangeSortBy(ShipmentField.VOLUME_CU3)}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Volumen (cu3)</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.VOLUME_CU3 && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(ShipmentField.ALIAS)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Alias</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.ALIAS && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(ShipmentField.PHONE)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Teléfono</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.PHONE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(ShipmentField.CITY)}
            >
              <div className="flex gap-2 items-center cursor-pointer truncate">
                <span>Ciudad</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.CITY && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(ShipmentField.DATE)}
              style={{ maxWidth: '7rem' }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Fecha de transacción</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.DATE && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th
              className="px-4 py-2"
              onClick={() => onChangeSortBy(ShipmentField.TRACKING)}
              style={{ maxWidth: '7rem' }}
            >
              <div className="flex gap-2 items-center cursor-pointer">
                <span>Estatus</span>
                <div className="h-4 w-4">
                  {sortBy.field == ShipmentField.TRACKING && (
                    <ChevronDownIcon
                      className={classNames(
                        'w-4 h-4',
                        sortBy.asc && 'rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </th>

            <th />
          </tr>
        </thead>
        <tbody>
          {shipments.map((shipment, index) => {
            const selected = selectedShipments.some((s) => s.id == shipment.id);

            return (
              <tr
                key={shipment.id}
                className={classNames(
                  'hover:bg-gray-200',
                  index % 2 == 0 && 'bg-gray-100',
                  selected && '!bg-blue-200'
                )}
              >
                <th className="pl-2 py-2">
                  <FormCheckbox
                    id={`select-${shipment.id}-${index}`}
                    name={`select-${shipment.id}-${index}`}
                    label=""
                    onChange={() => onSelectShipment(shipment)}
                    checked={selected}
                  />
                </th>
                <td className="px-2 py-1 font-semibold">{shipment.number}</td>
                <td className="px-2 py-1">{shipment.totalPieces}</td>
                <td className="px-2 py-1">{shipment.totalWeight}</td>
                <td className="px-2 py-1">{shipment.totalDimensionalWeight}</td>
                <td className="px-2 py-1">{shipment.totalVolumeCf3}</td>
                <td className="px-4 py-1">
                  {shipment.consignee.accountFullName}
                </td>
                <td className="px-4 py-1">
                  {shipment.consignee.listAccountPhone[0]?.phoneNumber}
                </td>
                <td className="px-4 py-1">{shipment.shippingLocation.name}</td>
                <td className="px-4 py-1">
                  {moment(shipment.creationDate).format('DD/MM/YYYY')}
                </td>
                <td className="px-4 py-1">{shipment.statusName}</td>
                <td className="pr-2 ">
                  <Popover
                    button={TableOptionsButton}
                    containerClassname="-mt-12 ml-4 mr-6 !-translate-y-12 border-2 z-40 fixed top-0  "
                  >
                    <div className="flex flex-col font-semibold cursor-pointer">
                      <div
                        onClick={() => {
                          getTracking(shipment);
                          setSelected(shipment);
                          setOpenModal(true);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-32 items-center">
                          <InformationCircleIcon className="w-5 h-5" />
                          <span>Detalles</span>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setSelected(shipment);
                          setOpenPrintModal(true);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-35 items-center">
                          <PrinterIcon className="w-5 h-5" />
                          <span>Documento Bolsillo</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setSelected(shipment);
                          setOpenPrintPiecesModal(true);
                          setSelectTypePiecesPrint(1);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-35 items-center">
                          <PrinterIcon className="w-5 h-5" />
                          <span>Etiqueta Bom Pequeña</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setSelected(shipment);
                          setOpenPrintPiecesModal(true);
                          setSelectTypePiecesPrint(2);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-35 items-center">
                          <PrinterIcon className="w-5 h-5" />
                          <span>Etiqueta Bom Grande</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setSelected(shipment);
                          setOpenPrintPiecesModal(true);
                          setSelectTypePiecesPrint(3);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-35 items-center">
                          <PrinterIcon className="w-5 h-5" />
                          <span>Etiqueta Bom Pequeña y Grande</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setSelected(shipment);
                          setOpenPrintModal(true);
                          setOpenPrintHelpModal(true);
                        }}
                        className="flex flex-1 flex-col text-sm text-gray-700 truncate hover:bg-main-500 hover:text-white"
                      >
                        <div className="flex gap-2 py-2 px-4 w-35 items-center">
                          <PrinterIcon className="w-5 h-5" />
                          <span>Etiqueta Zebra</span>
                        </div>
                      </div>
                    </div>
                  </Popover>
                </td>
              </tr>
            );
          })}

          {shipments.length == 0 && (
            <tr>
              <td colSpan={11} className="px-4 py-4 text-center">
                No hay guías en esta tabla.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {!!selected && (
        <ShipmentDetailsModal
          open={openModal}
          shipment={selected}
          setOpen={setOpenModal}
          tracking={tracking}
          onPrint={() => {}}
          loadingTracking={loadingtracking}
        />
      )}

      {!!selected && (
        <ShipmentPrintModal
          open={openPrintModal}
          shipment={selected}
          setOpen={setOpenPrintModal}
          onPrint={() => {}}
        />
      )}

      {!!selected && (
        <ShipmentPiecesPrintModal
          type={selectTypePiecesPrint}
          open={openPrintPiecesModal}
          shipment={selected}
          setOpen={setOpenPrintPiecesModal}
          onPrint={() => {}}
        />
      )}
    </div>
  );
};
